import axios from 'axios';
import AuthService from '../services/AuthService';



async function getScopeWithTableName(tableName) {
  const accessToken = AuthService.getCurrentUserToken();
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/scope/${tableName}`, {
          headers: {
              'x-access-token': accessToken
          }
      });
      console.log(response.data);
      return response.data;
  } catch (error) {
      console.error('Error fetching scope data:', error);
  }
};


export default {
  getScopeWithTableName
};
