import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthProvider';
import { List, ListItem, ListItemText, Typography, Paper } from '@mui/material';

const OffersPage = () => {
  const { token } = useAuth();
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/myOffers`, {
          headers: {
            'x-access-token': token
          }
        });
        setOffers(response.data);
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    if (token) {
      fetchOffers();
    }
  }, [token]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Tekliflerim
        </Typography>
        <Paper>
          <List>
            {offers.map((offer) => (
              <ListItem key={offer.id}>
                <ListItemText
                  primary={`Teklif ID: ${offer.id}`}
                  secondary={`Tutar: ${offer.amount} - Tarih: ${new Date(offer.date).toLocaleDateString()}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </div>
    </div>
  );
};

export default OffersPage;