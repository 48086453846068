export const category_map = {
    "01": "01 | MADENLERİN ARANMASI, ÇIKARILMASI, İŞLETİLMESİ, FİZİKİ VE KİMYASAL İŞLEME TABİ TUTULMASI SIRASINDA ORTAYA ÇIKAN ATIKLAR",
    "0101": "0101 | Maden Kazılarından Kaynaklanan Atıklar",
    "010101": "010101 | Metalik maden kazılarından kaynaklanan atıklar",
    "010102": "010102 | Metalik olmayan maden kazılarından kaynaklanan atıklar",
    "0103": "0103 | Metalik Minerallerin Fiziki ve Kimyasal Olarak İşlenmesinden Kaynaklanan Atıklar",
    "010304": "010304 | Sülfürlü cevherlerin işlenmesinden kaynaklanan asit üretici maden atıkları",
    "010305": "010305 | Tehlikeli madde içeren diğer maden atıkları",
    "010306": "010306 | 01 03 04 ve 01 03 05 dışındaki diğer maden atıkları",
    "010307": "010307 | Metalik minerallerin fiziki ve kimyasal işlenmesinden kaynaklanan tehlikeli maddeler içeren diğer atıklar",
    "010308": "010308 | 01 03 07 dışındaki diğer tozumsu ve pudramsı atıklar",
    "010309": "010309 | 01 03 10 dışındaki alüminyum oksit üretiminden çıkan kırmızı çamur",
    "010310": "010310 | 01 03 07 dışındaki alüminyum oksit üretiminden çıkan tehlikeli maddeler içeren kırmızı çamur",
    "010399": "010399 | Başka bir şekilde tanımlanmamış atıklar",
    "0104": "0104 | Metalik Olmayan Minerallerin Fiziki ve Kimyasal İşlemlerinden Kaynaklanan Atıklar",
    "010407": "010407 | Metalik olmayan minerallerin fiziki ve kimyasal işlenmesinden kaynaklanan tehlikeli maddeler içeren atıklar",
    "010408": "010408 | 01 04 07 dışındaki atık kaya ve çakıl taşı atıkları",
    "010409": "010409 | Atık kum ve killer",
    "010410": "010410 | 01 04 07 dışındaki tozumsu ve pudramsı atıklar",
    "010411": "010411 | 01 04 07 dışındaki potas ve kaya tuzu işlemesinden kaynaklanan atıklar",
    "010412": "010412 | 01 04 07 ve 01 04 11 dışındaki minerallerin yıkanması ve temizlenmesinden kaynaklanan ince taneli atıklar ve diğer atıklar",
    "010413": "010413 | 01 04 07 dışındaki taş yontma ve kesme işlemlerinden kaynaklanan atıklar",
    "010499": "010499 | Başka bir şekilde tanımlanmamış atıklar",
    "0105": "0105 | Sondaj Çamurları ve Diğer Sondaj Atıkları",
    "010504": "010504 | Tatlı su sondaj çamurları ve atıkları",
    "010505": "010505 | Yağ içeren sondaj çamurları ve atıkları",
    "010506": "010506 | Tehlikeli maddeler içeren sondaj çamurları ve diğer sondaj atıkları ",
    "010507": "010507 | 01 05 05 ve 01 05 06 dışındaki barit içeren sondaj çamurları ve atıkları",
    "010508": "010508 | 01 05 05 ve 01 05 06 dışındaki klorür içeren sondaj çamurları ve atıkları",
    "010599": "010599 | Başka bir şekilde tanımlanmamış atıklar",
    "02": "02 | TARIM, BAHÇIVANLIK, SU ÜRÜNLERİ, ORMANCILIK, AVCILIK VE BALIKÇILIK, GIDA HAZIRLAMA VE İŞLEMEDEN KAYNAKLANAN ATIKLAR",
    "0201": "0201 | Tarım, Bahçıvanlık, Su Ürünleri Üretimi, Ormancılık, Avcılık ve Balıkçılıktan Kaynaklanan Atıklar",
    "020101": "020101 | Yıkama ve temizleme işlemlerinden kaynaklanan çamurlar",
    "020102": "020102 | Hayvan dokusu atıkları",
    "020103": "020103 | Bitki dokusu atıkları (1) (8)",
    "020104": "020104 | Atık plastikler (ambalajlar hariç)",
    "020106": "020106 | Ayrı toplanmış ve saha dışında işlem görecek hayvan dışkısı, idrar ve tezek (ve bunlarla temas etmiş saman dahil), akan sıvılar (8)",
    "020107": "020107 | Ormancılık atıkları (1) (8)",
    "020108": "020108 | Tehlikeli maddeler içeren zirai kimyasal atıklar",
    "020109": "020109 | 02 01 08 dışındaki zirai kimyasal atıkları",
    "020110": "020110 | Atık metal",
    "020199": "020199 | Başka bir şekilde tanımlanmamış atıklar",
    "0202": "0202 | Et, Balık ve Diğer Hayvansal Kökenli Gıda Maddelerinin Hazırlanmasından ve İşlenmesinden Kaynaklanan Atıklar",
    "020201": "020201 | Yıkama ve temizlemeden kaynaklanan çamurlar",
    "020202": "020202 | Hayvan dokusu atığı",
    "020203": "020203 | Tüketime ya da işlenmeye uygun olmayan maddeler",
    "020204": "020204 | İşletme sahası içerisindeki atıksu arıtımından kaynaklanan çamurlar (8)",
    "020299": "020299 | Başka bir şekilde tanımlanmamış atıklar",
    "0203": "0203 | Meyve, Sebze, Tahıl, Yenilebilir Yağlar, Kakao, Kahve, Çay ve Tütünün Hazırlanmasından ve İşlenmesinden; Konserve Üretiminden, Maya ve Maya Özütü Üretiminden, Melas Hazırlanması ve Fermantasyonundan Kaynaklanan Atıklar",
    "020301": "020301 | Yıkama, temizleme, soyma, santrifüj ve ayırma işlemlerinden kaynaklanan çamurlar",
    "020302": "020302 | Koruyucu katkı maddelerinden kaynaklanan atıklar",
    "020303": "020303 | Çözücü ekstraksiyonundan kaynaklanan atıklar",
    "020304": "020304 | Tüketime ya da işlenmeye uygun olmayan maddeler (1) (8)",
    "020305": "020305 | İşletme sahası içerisindeki atıksu arıtımından kaynaklanan atıklar (8)",
    "020399": "020399 | Başka bir şekilde tanımlanmamış atıklar",
    "0204": "0204 | Şeker Üretiminden Kaynaklanan Atıklar",
    "020401": "020401 | Şeker pancarının temizlenmesinden ve yıkanmasından kaynaklanan toprak",
    "020402": "020402 | Standart dışı kalsiyum karbonat",
    "020403": "020403 | İşletme sahası içerisindeki atıksu arıtımından kaynaklanan çamurlar (8)",
    "020499": "020499 | Başka bir şekilde tanımlanmamış atıklar",
    "0205": "0205 | Süt Ürünleri Endüstrisinden Kaynaklanan Atıklar",
    "020501": "020501 | Tüketime ya da işlenmeye uygun olmayan maddeler",
    "020502": "020502 | İşletme sahası içerisindeki atıksu arıtımından kaynaklanan çamurlar (8)",
    "020599": "020599 | Başka bir şekilde tanımlanmamış atıklar",
    "0206": "0206 | Unlu Mamuller ve Şekerleme Endüstrisinden Kaynaklanan Atıklar",
    "020601": "020601 | Tüketime ve işlenmeye uygun olmayan maddeler",
    "020602": "020602 | Koruyucu katkı maddelerinden kaynaklanan atıklar",
    "020603": "020603 | İşletme sahası içerisindeki atıksu arıtımından kaynaklanan çamurlar (8)",
    "020699": "020699 | Başka bir şekilde tanımlanmamış atıklar",
    "0207": "0207 | Alkollü ve Alkolsüz İçeceklerin (Kahve, Çay ve Kakao Hariç) Üretiminden Kaynaklanan Atıklar",
    "020701": "020701 | Hammaddelerin yıkanmasından, temizlenmesinden ve mekanik olarak sıkılmasından kaynaklanan atıklar",
    "020702": "020702 | Alkol damıtılmasından kaynaklanan atıklar",
    "020703": "020703 | Kimyasal işlem atıkları",
    "020704": "020704 | Tüketime ya da işlenmeye uygun olmayan maddeler (8)",
    "020705": "020705 | İşletme sahası içerisindeki atıksu arıtımından kaynaklanan çamurlar (8)",
    "020799": "020799 | Başka bir şekilde tanımlanmayan atıklar",
    "03": "03 | AHŞAP İŞLEME VE KAĞIT, KARTON, KAĞIT HAMURU, PANEL(SUNTA) VE MOBİLYA ÜRETİMİNDEN KAYNAKLANAN ATIKLAR",
    "0301": "0301 | Ağaç İşlemeden ve Sunta ve Mobilya Üretiminden Kaynaklanan Atıklar",
    "030101": "030101 | Ağaç kabuğu ve mantar atıkları (1) (8)",
    "030104": "030104 | Tehlikeli maddeler içeren talaş, yonga, kıymık, ahşap, kontraplak ve kaplamalar",
    "030105": "030105 | 03 01 04 dışındaki talaş, yonga, kıymık, ahşap, kontraplak ve kaplamalar (1) (8)",
    "030199": "030199 | Başka bir şekilde tanımlanmamış atıklar",
    "0302": "0302 | Ahşap Koruma Atıkları",
    "030201": "030201 | Halojenlenmemiş organik ahşap koruyucu maddeler",
    "030202": "030202 | Organoklorlu ahşap koruyucu maddeler",
    "030203": "030203 | Organometal içeren ahşap koruyucu maddeler",
    "030204": "030204 | İnorganik ahşap koruyucu maddeler ",
    "030205": "030205 | Tehlikeli maddeler içeren diğer ahşap koruyucuları",
    "030299": "030299 | Başka bir şekilde tanımlanmamış ahşap koruyucuları",
    "0303": "0303 | Kağıt Hamuru, Kağıt ve Kağıt Karton Üretim ve İşlenmesinden Kaynaklanan Atıklar",
    "030301": "030301 | Ağaç kabuğu ve odun atıkları (1) (8)",
    "030302": "030302 | Yeşil sıvı çamuru (pişirme sıvısı geri kazanımından)",
    "030305": "030305 | Kâğıt geri kazanım işleminden kaynaklanan mürekkep giderme çamurları",
    "030307": "030307 | Atık kâğıt ve kartonun hamur haline getirilmesi sırasında mekanik olarak ayrılan ıskartalar (8)",
    "030308": "030308 | Geri dönüşüme gitmek üzere sınıflandırılan kağıt ve kartondan kaynaklanan atıklar (8)",
    "030309": "030309 | Kireç çamuru atığı",
    "030310": "030310 | Mekanik ayırma sonucu oluşan elyaf ıskartaları, elyaf, dolgu ve yüzey kaplama maddesi çamuru (8)",
    "030311": "030311 | 03 03 10 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar (8)",
    "030399": "030399 | Başka bir şekilde tanımlanmamış atıklar",
    "04": "04 | DERİ, KÜRK VE TEKSTİL ENDÜSTRİLERİNDEN KAYNAKLANAN ATIKLAR",
    "0401": "0401 | Deri ve Kürk Endüstrisinden Kaynaklanan Atıklar",
    "040101": "040101 | Sıyırma ve kireçleme ile deriden et sıyırma işleminden kaynaklanan atıklar ",
    "040102": "040102 | Kireçleme atıkları",
    "040103": "040103 | Sıvı halde olmayan çözücüler içeren yağ giderme atıkları",
    "040104": "040104 | Krom içeren sepi şerbeti",
    "040105": "040105 | Krom içermeyen sepi şerbeti",
    "040106": "040106 | Saha içi atıksu arıtımından kaynaklanan krom içeren çamurlar",
    "040107": "040107 | Saha içi atıksu arıtımından kaynaklanan krom içermeyen çamurlar",
    "040108": "040108 | Krom içeren tabaklanmış atık deri (çivitli parçalar, tıraşlamalar, kesmeler, parlatma tozu)",
    "040109": "040109 | Perdah ve boyama atıkları",
    "040199": "040199 | Başka bir şekilde tanımlanmamış atıklar",
    "0402": "0402 | Tekstil Endüstrisinden Kaynaklanan Atıklar",
    "040209": "040209 | Kompozit malzeme atıkları (emprenye edilmiş tekstil, elastomer, plastomer)",
    "040210": "040210 | Doğal ürünlerden oluşan organik maddeler (örneğin yağ, mum)",
    "040214": "040214 | Organik çözücüler içeren perdah atıkları",
    "040215": "040215 | 04 02 14 dışındaki perdah atıkları",
    "040216": "040216 | Tehlikeli maddeler içeren boya maddeleri ve pigmentler",
    "040217": "040217 | 04 02 16 dışındaki boya maddeleri ve pigmentler",
    "040219": "040219 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "040220": "040220 | 04 02 19 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "040221": "040221 | İşlenmemiş tekstil elyafı atıkları",
    "040222": "040222 | İşlenmiş tekstil elyafı atıkları",
    "040299": "040299 | Başka bir şekilde tanımlanmamış atıklar",
    "05": "05 | PETROL RAFİNASYONU, DOĞAL GAZ SAFLAŞTIRMA VE KÖMÜRÜN PİROLİTİK İŞLENMESİNDEN KAYNAKLANAN ATIKLAR",
    "0501": "0501 | Petrol Rafinasyon Atıkları",
    "050102": "050102 | Tuz arındırma(tuz giderici) çamurları",
    "050103": "050103 | Tank dibi çamurları",
    "050104": "050104 | Asit alkil çamurları",
    "050105": "050105 | Petrol döküntüleri ",
    "050106": "050106 | İşletme ya da ekipman bakım çalışmalarından kaynaklanan yağlı çamurlar",
    "050107": "050107 | Asit ziftleri ",
    "050108": "050108 | Diğer ziftler ",
    "050109": "050109 | Saha içi atıksu arıtımından kaynaklanan tehlikeli madde içeren çamurlar ",
    "050110": "050110 | 05 01 09 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "050111": "050111 | Yakıtların bazlar ile temizlemesi sonucu oluşan atıklar ",
    "050112": "050112 | Yağ içeren asitler ",
    "050113": "050113 | Kazan besleme suyu çamurları",
    "050114": "050114 | Soğutma kolonlarından kaynaklanan atıklar",
    "050115": "050115 | Kullanılmış filtre killeri",
    "050116": "050116 | Petrol desülfürizasyonu sonucu oluşan kükürt içeren atıklar",
    "050117": "050117 | Bitüm",
    "050199": "050199 | Başka bir şekilde tanımlanmamış atıklar",
    "0506": "0506 | Kömürün Pirolitik İşlenmesinden Kaynaklanan Atıklar",
    "050601": "050601 | Asit ziftleri ",
    "050603": "050603 | Diğer ziftler ",
    "050604": "050604 | Soğutma kolonlarından kaynaklanan atıklar",
    "050699": "050699 | Başka bir şekilde tanımlanmayan atıklar",
    "0507": "0507 | Doğal Gaz Saflaştırma ve Nakliyesinde Oluşan Atıklar",
    "050701": "050701 | Cıva içeren atıklar",
    "050702": "050702 | Kükürt içeren atıklar",
    "050799": "050799 | Başka bir şekilde tanımlanmamış atıklar",
    "06": "06 | İNORGANİK KİMYASAL İŞLEMLERDEN KAYNAKLANAN ATIKLAR",
    "0601": "0601 | Asitlerin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "060101": "060101 | Sülfürik asit ve sülfüröz asit",
    "060102": "060102 | Hidroklorik asit",
    "060103": "060103 | Hidroflorik asit",
    "060104": "060104 | Fosforik ve fosforöz asit",
    "060105": "060105 | Nitrik asit ve nitröz asit",
    "060106": "060106 | Diğer asitler",
    "060199": "060199 | Başka bir şekilde tanımlanmamış atıklar",
    "0602": "0602 | Bazların İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "060201": "060201 | Kalsiyum hidroksit",
    "060203": "060203 | Amonyum hidroksit",
    "060204": "060204 | Sodyum ve potasyum hidroksit",
    "060205": "060205 | Diğer bazlar",
    "060299": "060299 | Başka bir şekilde tanımlanmamış atıklar",
    "0603": "0603 | Tuzların ve Çözeltilerinin ve Metalik Oksitlerin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "060311": "060311 | Siyanür içeren katı tuzlar ve solüsyonlar",
    "060313": "060313 | Ağır metal içeren katı tuzlar ve solüsyonlar  ",
    "060314": "060314 | 06 03 11 ve 06 03 13 dışındaki katı tuzlar ve solüsyonlar",
    "060315": "060315 | Ağır metal içeren metal oksitler",
    "060316": "060316 | 06 03 15 dışındaki diğer metal oksitler",
    "060399": "060399 | Başka bir şekilde tanımlanmamış atıklar",
    "0604": "0604 | 06 03 Dışındaki Metal İçeren Atıklar",
    "060403": "060403 | Arsenik içeren atıklar ",
    "060404": "060404 | Cıva içeren atıklar ",
    "060405": "060405 | Başka ağır metaller içeren atıklar ",
    "060499": "060499 | Başka bir şekilde tanımlanmamış atıklar ",
    "0605": "0605 | İşletme Sahası İçerisindeki Atıksu Arıtımından Kaynaklanan Çamurlar",
    "060502": "060502 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "060503": "060503 | 06 05 02 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "0606": "0606 | Kükürtlü Kimyasallardan, Kükürtleyici Kimyasal İşlemlerinin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar ",
    "060602": "060602 | Tehlikeli kükürt bileşenleri içeren atıklar",
    "060603": "060603 | 06 06 02 dışındaki kükürt bileşenlerini içeren atıklar",
    "060699": "060699 | Başka bir şekilde tanımlanmamış atıklar",
    "0607": "0607 | Halojenlerin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) ve Halojenli Kimyasal İşlemlerden Kaynaklanan Atıklar",
    "060701": "060701 | Elektrolizden kaynaklanan asbest içeren atıklar ",
    "060702": "060702 | Klor üretiminden kaynaklanan aktif karbon ",
    "060703": "060703 | Cıva içeren baryum sülfat çamuru",
    "060704": "060704 | Çözeltiler ve asitler, örneğin kontakt asidi",
    "060799": "060799 | Başka bir şekilde tanımlanmamış atıklar",
    "0608": "0608 | Silikon ve Silikon Türevlerinin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "060802": "060802 | Zararlı klorosilan içeren atıklar",
    "060899": "060899 | Başka bir şekilde tanımlanmamış atıklar",
    "0609": "0609 | Fosforlu Kimyasalların İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) ve Fosforlu Kimyasal İşlenmesinden Kaynaklanan Atıklar",
    "060902": "060902 | Fosforlu cüruf",
    "060903": "060903 | Tehlikeli maddeler içeren ya da tehlikeli maddelerle kontamine olmuş kalsiyum bazlı reaksiyon atıkları ",
    "060904": "060904 | 06 09 03 dışındaki kalsiyum bazlı reaksiyon atıkları",
    "060999": "060999 | Başka bir şekilde tanımlanmamış atıklar",
    "0610": "0610 | Gübre Üretimi ve Azotlu Kimyasalların İşlenmesi ve Azot Kimyasalları İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "061002": "061002 | Tehlikeli maddeler içeren atıklar",
    "061099": "061099 | Başka bir şekilde tanımlanmamış atıklar",
    "0611": "0611 | İnorganik Pigmentlerin ve Opaklaştırıcıların İmalatından Kaynaklanan Atıklar",
    "061101": "061101 | Titanyum dioksit üretiminden kaynaklanan kalsiyum bazlı reaksiyon atıkları",
    "061199": "061199 | Başka bir şekilde tanımlanmamış atıklar",
    "0613": "0613 | Başka Bir Şekilde Tanımlanmamış İnorganik Kimyasal İşlemlerden Kaynaklanan Atıklar",
    "061301": "061301 | İnorganik bitki koruma ürünleri, ahşap koruma ürünleri ve diğer biyositler",
    "061302": "061302 | Kullanılmış aktif karbon (06 07 02 hariç)",
    "061303": "061303 | Karbon siyahı",
    "061304": "061304 | Asbest işlenmesinden kaynaklanan atıklar",
    "061305": "061305 | Kurum",
    "061399": "061399 | Başka bir şekilde tanımlanmamış atıklar",
    "07": "07 | ORGANİK KİMYASAL İŞLEMLERDEN KAYNAKLANAN ATIKLAR",
    "0701": "0701 | Temel Organik Kimyasal Maddelerin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "070101": "070101 | Su bazlı yıkama sıvıları ve ana çözeltiler ",
    "070103": "070103 | Halojenli organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070104": "070104 | Diğer organik çözücüler, yıkama sıvıları ve ana çözeltiler ",
    "070107": "070107 | Halojenli dip tortusu ve reaksiyon kalıntıları ",
    "070108": "070108 | Diğer dip tortusu ve reaksiyon kalıntıları",
    "070109": "070109 | Halojenli filtre keki ve kullanılmış absorbanlar ",
    "070110": "070110 | Diğer filtre kekleri ve kullanılmış absorbanlar ",
    "070111": "070111 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "070112": "070112 | 07 01 11 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar ",
    "070199": "070199 | Başka şekilde tanımlanmayan atıklar",
    "0702": "0702 | Plastiklerin, Sentetik Kauçuk ve Yapay Elyafların İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "070201": "070201 | Su bazlı yıkama sıvıları ve ana çözeltiler ",
    "070203": "070203 | Halojenli organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070204": "070204 | Diğer organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070207": "070207 | Halojenli dip tortusu ve reaksiyon kalıntıları",
    "070208": "070208 | Diğer dip tortusu ve reaksiyon kalıntıları",
    "070209": "070209 | Halojenli filtre kekleri ve kullanılmış absorbanlar",
    "070210": "070210 | Diğer filtre kekleri ve kullanılmış absorbanlar",
    "070211": "070211 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "070212": "070212 | 07 02 11 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "070213": "070213 | Atık plastik",
    "070214": "070214 | Tehlikeli maddeler içeren katkı maddelerinin atıkları",
    "070215": "070215 | 07 02 14 dışındaki katkı maddelerinin atıkları",
    "070216": "070216 | Zararlı silikonlar içeren atıklar",
    "070217": "070217 | 07 02 16 dışında silikon içeren atıklar",
    "070299": "070299 | Başka bir şekilde tanımlanmamış atıklar",
    "0703": "0703 | Organik Boyaların ve Pigmentlerin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar (06 11 dışındaki)",
    "070301": "070301 | Su bazlı yıkama sıvıları ve ana çözeltiler",
    "070303": "070303 | Halojenli organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070304": "070304 | Diğer organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070307": "070307 | Halojenli dip tortusu ve reaksiyon kalıntıları",
    "070308": "070308 | Diğer dip tortusu ve reaksiyon kalıntıları",
    "070309": "070309 | Halojenli filtre kekleri ve kullanılmış absorbanlar ",
    "070310": "070310 | Diğer filtre kekleri ve kullanılmış absorbanlar",
    "070311": "070311 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "070312": "070312 | 07 03 11 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "070399": "070399 | Başka bir şekilde tanımlanmamış atıklar",
    "0704": "0704 | Organik Bitki Koruma Ürünlerinin (02 01 08 ve 02 01 09 hariç), Ahşap Koruyucu Olarak Kullanılan Maddelerin (Ajanlarının) (03 02 Hariç) ve Diğer Biyositlerin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "070401": "070401 | Su bazlı yıkama sıvıları ve ana çözeltiler",
    "070403": "070403 | Halojenli organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070404": "070404 | Diğer organik çözücüler, yıkama sıvıları ve ana çözeltiler ",
    "070407": "070407 | Halojenli dip tortusu ve reaksiyon kalıntıları ",
    "070408": "070408 | Diğer dip tortusu ve reaksiyon kalıntıları",
    "070409": "070409 | Halojenli filtre kekleri ve kullanılmış absorbanlar",
    "070410": "070410 | Diğer filtre kekleri ve kullanılmış absorbanlar",
    "070411": "070411 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "070412": "070412 | 07 04 11 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "070413": "070413 | Tehlikeli madde içeren katı atıklar ",
    "070499": "070499 | Başka bir şekilde tanımlanmamış atıklar",
    "0705": "0705 | İlaçların İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "070501": "070501 | Su bazlı yıkama sıvıları ve ana çözeltiler",
    "070503": "070503 | Halojenli organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070504": "070504 | Diğer organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070507": "070507 | Halojenli dip tortusu ve reaksiyon kalıntıları",
    "070508": "070508 | Diğer dip tortusu ve reaksiyon kalıntıları",
    "070509": "070509 | Halojenli filtre kekleri ve kullanılmış absorbanlar",
    "070510": "070510 | Diğer filtre tabakaları kekleri, kullanılmış absorbanlar",
    "070511": "070511 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "070512": "070512 | 07 05 11 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "070513": "070513 | Tehlikeli madde içeren katı atıklar",
    "070514": "070514 | 07 05 13 dışındaki katı atıklar",
    "070599": "070599 | Başka bir şekilde tanımlanmamış atıklar",
    "0706": "0706 | Yağ, Gres, Sabun, Deterjan, Dezenfektan ve Kozmetiklerin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "070601": "070601 | Su bazlı yıkama sıvıları ve ana çözeltiler",
    "070603": "070603 | Halojenli organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070604": "070604 | Diğer organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070607": "070607 | Halojenli dip tortuları ve reaksiyon kalıntıları",
    "070608": "070608 | Diğer dip tortuları ve reaksiyon kalıntıları",
    "070609": "070609 | Halojenli filtre kekleri ve kullanılmış absorbanlar",
    "070610": "070610 | Diğer filtre kekleri ve kullanılmış absorbanlar",
    "070611": "070611 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "070612": "070612 | 07 06 11 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "070699": "070699 | Başka bir şekilde tanımlanmamış atıklar",
    "0707": "0707 | Başka Bir Şekilde Tanımlanmamış Kimyasal ve Kimyasal Ürünlerinin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "070701": "070701 | Su bazlı yıkama sıvıları ve ana çözeltiler",
    "070703": "070703 | Halojenli organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070704": "070704 | Diğer organik çözücüler, yıkama sıvıları ve ana çözeltiler",
    "070707": "070707 | Halojenli dip tortusu ve reaksiyon kalıntıları",
    "070708": "070708 | Diğer dip tortusu ve reaksiyon kalıntıları",
    "070709": "070709 | Halojenli filtre kekleri ve kullanılmış absorbanlar",
    "070710": "070710 | Diğer filtre kekleri ve kullanılmış absorbanlar",
    "070711": "070711 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "070712": "070712 | 07 07 11 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "070799": "070799 | Başka bir şekilde tanımlanmamış atıklar",
    "08": "08 | ASTARLAR (BOYALAR, VERNİKLER VE VİTRİFİYE EMAYELER), YAPIŞKANLAR, MACUNLAR VE BASKI MÜREKKEPLERİNİN ÜRETİM, FORMÜLASYON, TEDARİK VE KULLANIMINDAN (İFTK) KAYNAKLANAN ATIKLAR",
    "0801": "0801 | Boya ve Verniğin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) ve Sökülmesinden Kaynaklanan Atıklar",
    "080111": "080111 | Organik çözücüler ya da diğer tehlikeli maddeler içeren atık boya ve vernikler",
    "080112": "080112 | 08 01 11 dışındaki atık boya ve vernikler",
    "080113": "080113 | Organik çözücüler ya da diğer tehlikeli maddeler içeren boya ve vernik çamurları",
    "080114": "080114 | 08 01 13 dışındaki boya ve vernik çamurları",
    "080115": "080115 | Organik çözücüler ya da diğer tehlikeli maddeler içeren boya ve vernikli sulu çamurlar",
    "080116": "080116 | 08 01 15 dışındaki boya ve vernik içeren sulu çamurlar",
    "080117": "080117 | Organik çözücüler ya da diğer tehlikeli maddeler içeren boya ve verniğin sökülmesinden kaynaklanan atıklar ",
    "080118": "080118 | 08 01 17 dışındaki boya ve vernik sökülmesinden kaynaklanan atıklar",
    "080119": "080119 | Organik çözücüler ya da diğer tehlikeli maddeler içeren boya ve vernik sökülmesinden kaynaklanan sulu süspansiyonlar ",
    "080120": "080120 | 08 01 19 dışındaki sulu boya ya da vernik içeren sulu süspansiyonlar",
    "080121": "080121 | Boya ya da vernik sökücü atıkları",
    "080199": "080199 | Başka bir şekilde tanımlanmamış atıklar",
    "0802": "0802 | Diğer Kaplama Maddelerinin (Seramik Kaplama Dahil) İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "080201": "080201 | Atık kaplama tozları",
    "080202": "080202 | Seramik malzemeler içeren sulu çamurlar",
    "080203": "080203 | Seramik malzemeler içeren sulu süspansiyonlar",
    "080299": "080299 | Başka bir şekilde tanımlanmamış atıklar",
    "0803": "0803 | Baskı Mürekkeplerinin İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar",
    "080307": "080307 | Mürekkep içeren sulu çamurlar",
    "080308": "080308 | Mürekkep içeren sulu sıvı atıklar",
    "080312": "080312 | Tehlikeli maddeler içeren mürekkep atıkları",
    "080313": "080313 | 08 03 12 dışındaki mürekkep atıkları",
    "080314": "080314 | Tehlikeli maddeler içeren mürekkep çamurları",
    "080315": "080315 | 08 05 14 dışındaki mürekkep çamurları",
    "080316": "080316 | Atık aşındırma solüsyonları",
    "080317": "080317 | Tehlikeli maddeler içeren atık baskı tonerleri",
    "080318": "080318 | 08 03 17 dışındaki atık baskı tonerleri",
    "080319": "080319 | Dağıtıcı yağ",
    "080399": "080399 | Başka bir şekilde tanımlanmamış atıklar",
    "0804": "0804 | Yapışkanlar ve Yalıtıcıların İmalat, Formülasyon, Tedarik ve Kullanımından (İFTK) Kaynaklanan Atıklar (Su Geçirmeyen Ürünler Dahil)",
    "080409": "080409 | Organik çözücüler ya da diğer tehlikeli maddeler içeren atık yapışkanlar ve dolgu macunları",
    "080410": "080410 | 08 04 09 dışındaki atık yapışkanlar ve dolgu macunları",
    "080411": "080411 | Organik çözücüler ya da diğer tehlikeli maddeler içeren yapışkan ve dolgu macunu çamurları",
    "080412": "080412 | 08 04 11 dışındaki yapışkan ve dolgu macunu çamurları",
    "080413": "080413 | Organik çözücüler ya da diğer tehlikeli maddeler içeren sulu yapışkan veya dolgu macunu çamurları",
    "080414": "080414 | 08 04 13 dışındaki sulu organik yapışkan veya dolgu macunu çamurları",
    "080415": "080415 | Organik çözücüler ya da diğer tehlikeli maddeler içeren sulu yapışkan veya dolgu macunlarının sıvı atıkları  ",
    "080416": "080416 | 08 04 15 dışındaki yapışkan veya dolgu macunlarının sulu atıkları",
    "080417": "080417 | Reçine yağı",
    "080499": "080499 | Başka bir şekilde tanımlanmamış atıklar",
    "0805": "0805 | 08’de Başka Şekilde Tanımlanmamış Atıklar",
    "080501": "080501 | Atık izosiyanatlar",
    "09": "09 | FOTOĞRAF ENDÜSTRİSİNDEN KAYNAKLANAN ATIKLAR",
    "0901": "0901 | Fotoğraf Endüstrisi Atıkları",
    "090101": "090101 | Su bazlı banyo ve aktifleştirici solüsyonları",
    "090102": "090102 | Su bazlı ofset plakası banyo solüsyonu",
    "090103": "090103 | Çözücü bazlı banyo solüsyonları",
    "090104": "090104 | Sabitleyici solüsyonlar",
    "090105": "090105 | Ağartıcı solüsyonları ve ağartıcı sabitleyici solüsyonlar",
    "090106": "090106 | Fotoğrafçılık atıklarının saha içi arıtılmasından oluşan gümüş içeren atıklar ",
    "090107": "090107 | Gümüş veya da gümüş bileşenleri içeren fotoğraf filmi ve kâğıdı",
    "090108": "090108 | Gümüş veya gümüş bileşenleri içermeyen fotoğraf filmi ve kâğıdı",
    "090110": "090110 | Pilsiz çalışan tek kullanımlık fotoğraf makineleri",
    "090111": "090111 | 16 06 01, 16 06 02 ya da 16 06 03’ün altında geçen pillerle çalışan tek kullanımlık fotoğraf makineleri",
    "090112": "090112 | 09 01 11 dışındaki pille çalışan tek kullanımlık fotoğraf makineleri",
    "090113": "090113 | 09 01 06 dışındaki gümüş geri kazanımı için yapılan arıtmadan kalan sulu sıvı atıklar",
    "090199": "090199 | Başka bir şekilde tanımlanmamış atıklar",
    "10": "10 | ISIL İŞLEMLERDEN KAYNAKLANAN ATIKLAR",
    "1001": "1001 | Enerji Santrallerinden ve Diğer Yakma Tesislerinden Kaynaklanan Atıklar (19 Hariç)",
    "100101": "100101 | (10 01 04’ün altındaki kazan tozu hariç) dip külü, cüruf ve kazan tozu ",
    "100102": "100102 | Uçucu kömür külü",
    "100103": "100103 | Turba ve işlenmenmiş odundan kaynaklanan uçucu kül",
    "100104": "100104 | Uçucu yağ külü ve kazan tozu",
    "100105": "100105 | Baca gazı kükürt giderme işleminden (desülfürizasyon) çıkan kalsiyum bazlı katı atıklar",
    "100107": "100107 | Baca gazı kükürt giderme işleminden (desülfürizasyon) çıkan kalsiyum bazlı çamurlar",
    "100109": "100109 | Sülfürik asit",
    "100113": "100113 | Yakıt olarak kullanılan emülsifiye hidrokarbonların uçucu külleri",
    "100114": "100114 | Atıkların beraber yakılmasından kaynaklanan ve tehlikeli maddeler içeren dip külü, cüruf ve kazan tozu",
    "100115": "100115 | 10 01 14 dışındaki beraber yakılmadan kaynaklanan dip külü, cüruf ve kazan tozu",
    "100116": "100116 | Atıkların beraber yakılmasından kaynaklanan ve tehlikeli maddeler içeren uçucu kül",
    "100117": "100117 | 10 01 16 dışındaki beraber yakmadan kaynaklanan uçucu kül",
    "100118": "100118 | Tehlikeli maddeler içeren gaz temizleme atıkları",
    "100119": "100119 | 10 01 05, 10 01 07 ve 10 01 18 dışındaki gaz temizleme atıkları",
    "100120": "100120 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "100121": "100121 | 10 01 20 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "100122": "100122 | Kazan temizlemesi sonucu çıkan tehlikeli maddeler içeren sulu çamurlar",
    "100123": "100123 | 10 01 22 dışındaki kazan temizlemesi sonucu çıkan sulu çamurlar",
    "100124": "100124 | Akışkan yatak kumları ",
    "100125": "100125 | Termik santrallerin yakıt depolama ve hazırlama işlemlerinden çıkan atıklar",
    "100126": "100126 | Soğutma suyunun arıtılmasından çıkan atıklar",
    "100199": "100199 | Başka bir şekilde tanımlanmamış atıklar",
    "1002": "1002 | Demir ve Çelik Endüstrisinden Kaynaklanan Atıklar",
    "100201": "100201 | Cüruf işleme atıkları",
    "100202": "100202 | İşlenmemiş cüruf",
    "100207": "100207 | Tehlikeli maddeler içeren gazların arıtımı sonucu ortaya çıkan katı atıklar",
    "100208": "100208 | 10 02 07 dışında gaz arıtımı sonucu ortaya çıkan katı atıklar",
    "100210": "100210 | Haddehane tufalı",
    "100211": "100211 | Soğutma suyunun arıtılmasından kaynaklanan yağ içerikli atıklar",
    "100212": "100212 | 10 02 11 dışındaki soğutma suyu arıtma atıkları",
    "100213": "100213 | Gaz arıtımı sonucu oluşan ve tehlikeli maddeler içeren çamurlar ve filtre kekleri",
    "100214": "100214 | 10 02 13 dışındaki gaz arıtımı sonucu oluşan çamurlar ve filtre kekleri",
    "100215": "100215 | Diğer çamurlar ve filtre kekleri",
    "100299": "100299 | Başka bir şekilde tanımlanmamış atıklar",
    "1003": "1003 | Alüminyum Isıl Metalurjisinden Kaynaklanan Atıklar",
    "100302": "100302 | Anot hurdaları",
    "100304": "100304 | Birincil üretim cürufları",
    "100305": "100305 | Atık alüminyum oksit",
    "100308": "100308 | İkincil üretimden kaynaklanan tuz cürufları",
    "100309": "100309 | İkincil üretimden kaynaklanan kara cüruflar",
    "100315": "100315 | Suyla temas halinde tehlikeli miktarlarda alevlenebilir gazlar çıkaran yanıcı veya yayılabilir köpükler",
    "100316": "100316 | 10 03 15 dışındaki köpükler",
    "100317": "100317 | Anot üretiminden kaynaklanan katranlı atıklar",
    "100318": "100318 | 10 03 17 dışındaki anot üretiminden kaynaklanan karbon içerikli atıklar",
    "100319": "100319 | Tehlikeli maddeler içeren baca gazı tozu",
    "100320": "100320 | 10 03 19 dışındaki baca gazı tozu",
    "100321": "100321 | Tehlikeli maddeler içeren diğer partiküller ve tozlar (öğütücü değirmen tozu dâhil)",
    "100322": "100322 | 10 03 21 dışındaki partiküller ve tozlar (öğütücü değirmen tozu dâhil)",
    "100323": "100323 | Tehlikeli maddeler içeren gaz arıtımı katı atıkları",
    "100324": "100324 | 10 03 23 dışındaki gaz arıtımı katı atıkları",
    "100325": "100325 | Tehlikeli maddeler içeren gaz arıtımı çamurları ve filtre kekleri",
    "100326": "100326 | 10 03 25 dışındaki gaz arıtımı çamurları ve filtre kekleri",
    "100327": "100327 | Soğutma suyunun arıtılmasından kaynaklanan yağ içerikli atıklar",
    "100328": "100328 | 10 03 27 dışındaki soğutma suyu arıtma atıkları",
    "100329": "100329 | Tehlikeli maddeler içeren tuz cürufları ve kara cürufların işlenmesinden çıkan atıklar",
    "100330": "100330 | 10 03 29 dışındaki tuz cürufları ve kara cürufların işlenmesinden çıkan atıklar",
    "100399": "100399 | Başka bir şekilde tanımlanmamış atıklar",
    "1004": "1004 | Kurşun Isıl Metalurjisinden Kaynaklanan Atıklar ",
    "100401": "100401 | Birincil ve ikincil üretim cürufları",
    "100402": "100402 | Birincil ve ikincil üretimden kaynaklanan cüruf ve köpükler",
    "100403": "100403 | Kalsiyum arsenat",
    "100404": "100404 | Baca gazı tozu",
    "100405": "100405 | Diğer partiküller ve toz",
    "100406": "100406 | Gaz arıtımından kaynaklanan katı atıklar  ",
    "100407": "100407 | Gaz arıtım çamurları ve filtre kekleri",
    "100409": "100409 | Soğutma suyunun arıtılmasından kaynaklanan yağ içerikli atıklar",
    "100410": "100410 | 10 04 09 dışındaki soğutma suyu arıtma atıkları",
    "100499": "100499 | Başka bir şekilde tanımlanmamış atıklar",
    "1005": "1005 | Çinko Isıl Metalurjisinden Kaynaklanan Atıklar",
    "100501": "100501 | Birincil ve ikincil üretim cürufları",
    "100503": "100503 | Baca gazı tozu",
    "100504": "100504 | Diğer partiküller ve toz",
    "100505": "100505 | Gaz arıtımından kaynaklanan katı atıklar",
    "100506": "100506 | Gaz arıtım çamurları ve filtre kekleri",
    "100508": "100508 | Soğutma suyunun arıtılmasından kaynaklanan yağ içerikli atıklar",
    "100509": "100509 | 10 05 08 dışındaki soğutma suyu arıtma atıkları",
    "100510": "100510 | Suyla temas halinde tehlikeli miktarlarda alevlenebilir gazlar çıkaran yanıcı veya yayılabilir cüruf ve köpükler",
    "100511": "100511 | 10 05 10 dışındaki cüruf ve köpükler",
    "100599": "100599 | Başka bir şekilde tanımlanmamış atıklar",
    "1006": "1006 | Bakır Isıl Metalurjisinden Kaynaklanan Atıklar",
    "100601": "100601 | Birincil ve ikincil üretim cürufları",
    "100602": "100602 | Birincil ve ikincil üretimden kaynaklanan cüruf ve köpükler",
    "100603": "100603 | Baca gazı tozu",
    "100604": "100604 | Diğer partiküller ve toz",
    "100606": "100606 | Gaz arıtımından kaynaklanan katı atıklar",
    "100607": "100607 | Gaz arıtımından kaynaklanan çamurlar ve filtre kekleri",
    "100609": "100609 | Soğutma suyunun arıtılmasından kaynaklanan yağ içeren atıklar",
    "100610": "100610 | 10 06 09 dışındaki soğutma suyu arıtma atıkları",
    "100699": "100699 | Başka bir şekilde tanımlanmamış atıklar",
    "1007": "1007 | Gümüş, Altın ve Platin Isıl Metalurjisinden Kaynaklanan Atıklar",
    "100701": "100701 | Birincil ve ikincil üretim cürufları",
    "100702": "100702 | Birincil ve ikincil üretimden kaynaklanan cüruf ve köpükler",
    "100703": "100703 | Gaz arıtımından kaynaklanan katı atıklar",
    "100704": "100704 | Diğer partiküller ve toz",
    "100705": "100705 | Gaz arıtımından kaynaklanan çamurlar ve filtre kekleri",
    "100707": "100707 | Soğutma suyunun arıtılmasından kaynaklanan yağ içeren atıklar",
    "100708": "100708 | 10 07 07 dışındaki soğutma suyu arıtma atıkları",
    "100799": "100799 | Başka bir şekilde tanımlanmamış atıklar",
    "1008": "1008 | Demir Dışı Isıl Metalurjisinden Kaynaklanan Atıklar",
    "100804": "100804 | Partiküller ve toz",
    "100808": "100808 | Birincil ve ikincil üretimden kaynaklanan tuz cürufu",
    "100809": "100809 | Diğer cüruflar",
    "100810": "100810 | Suyla temas halinde tehlikeli miktarlarda alevlenebilir gazlar çıkaran yanıcı veya yayılabilir cüruf ve köpükler",
    "100811": "100811 | 10 08 10 dışındaki cüruf, toz ve kırpıntılar",
    "100812": "100812 | Anot üretiminden kaynaklanan katran içeren atıklar",
    "100813": "100813 | 10 08 12 dışındaki anot üretiminden kaynaklanan karbon içerikli atıklar ",
    "100814": "100814 | Anot hurdası",
    "100815": "100815 | Tehlikeli maddeler içeren baca gazı tozu",
    "100816": "100816 | 10 08 15 dışındaki baca gazı tozu",
    "100817": "100817 | Baca gazı arıtımından kaynaklanan ve tehlikeli maddeler içeren çamurlar ve filtre kekleri",
    "100818": "100818 | 10 08 17 dışındaki gaz arıtma çamurları ve filtre kekleri",
    "100819": "100819 | Soğutma suyunun arıtılmasından kaynaklanan yağ içeren atıklar",
    "100820": "100820 | 10 08 19 dışındaki soğutma suyu arıtma atıkları",
    "100899": "100899 | Başka bir şekilde tanımlanmamış atıklar",
    "1009": "1009 | Demir Döküm İşleminden Kaynaklanan Atıklar",
    "100903": "100903 | Ocak cürufları",
    "100905": "100905 | Henüz döküm yapılamamış, tehlikeli madde içeren maça ve kum döküm kalıpları ",
    "100906": "100906 | 10 09 05 dışında henüz döküm yapılamamış maça ve kum döküm kalıpları",
    "100907": "100907 | Döküm yapılmış tehlikeli madde içeren maça ve kum döküm kalıpları",
    "100908": "100908 | 10 09 07 dışında döküm yapılmış maça ve kum döküm kalıpları",
    "100909": "100909 | Tehlikeli maddeler içeren baca gazı tozu",
    "100910": "100910 | 10 09 09 dışındaki baca gazı tozu",
    "100911": "100911 | Tehlikeli maddeler içeren diğer partiküller",
    "100912": "100912 | 10 09 11 dışındaki diğer partiküller",
    "100913": "100913 | Tehlikeli maddeler içeren atık bağlayıcılar",
    "100914": "100914 | 10 09 13 dışındaki atık bağlayıcılar",
    "100915": "100915 | Tehlikeli madde içeren çatlak belirleme kimyasalları atığı",
    "100916": "100916 | 10 09 15 dışındaki çatlak belirleme kimyasalları atığı",
    "100999": "100999 | Başka bir şekilde tanımlanmamış atıklar",
    "1010": "1010 | Demir Dışı Döküm Atıkları",
    "101003": "101003 | Ocak cürufları",
    "101005": "101005 | Henüz döküm yapılamamış, tehlikeli madde içeren maça ve kum döküm kalıpları ",
    "101006": "101006 | 10 10 05 dışındaki henüz döküm yapılamamış maça ve kum döküm kalıpları",
    "101007": "101007 | Döküm yapılmış tehlikeli madde içeren maça ve kum döküm kalıpları",
    "101008": "101008 | 10 10 07 dışındaki döküm yapılmış maça ve kum döküm kalıpları",
    "101009": "101009 | Tehlikeli maddeler içeren baca gazı tozu",
    "101010": "101010 | 10 10 09 dışındaki baca gazı tozu",
    "101011": "101011 | Tehlikeli maddeler içeren diğer partiküller",
    "101012": "101012 | 10 10 11 dışındaki diğer partiküller",
    "101013": "101013 | Tehlikeli maddeler içeren bağlayıcı atıkları",
    "101014": "101014 | 10 10 13 dışındaki bağlayıcı atıkları",
    "101015": "101015 | Tehlikeli madde içeren çatlak belirleme kimyasalları atığı",
    "101016": "101016 | 10 10 15 dışındaki çatlak belirleme kimyasalları atığı",
    "101099": "101099 | Başka bir şekilde tanımlanmamış atıklar",
    "1011": "1011 | Cam ve Cam Ürünleri Üretim Atıkları",
    "101103": "101103 | Cam elyaf atıkları",
    "101105": "101105 | Partiküller ve toz",
    "101109": "101109 | Isıl işlemden önce hazırlanan tehlikeli maddeler içeren harman atığı",
    "101110": "101110 | 10 11 09 dışında ısıl işlemden önce hazırlanan harman atığı",
    "101111": "101111 | Ağır metaller içeren küçük parçacıklar ve cam tozu halinde atık cam(örneğin katot ışın tüplerinden)",
    "101112": "101112 | 10 11 11 dışındaki atık camlar",
    "101113": "101113 | Tehlikeli maddeler içeren cam parlatma ve öğütme çamuru",
    "101114": "101114 | 10 11 13 dışındaki cam parlatma ve öğütme çamuru",
    "101115": "101115 | Baca gazı arıtımından kaynaklanan tehlikeli maddeler içeren katı atıklar",
    "101116": "101116 | 10 11 15 dışında baca gazı arıtımından kaynaklanan katı atıklar  ",
    "101117": "101117 | Baca gazı arıtımından kaynaklanan ve tehlikeli maddeler içeren çamurlar ve filtre kekleri",
    "101118": "101118 | 10 11 17 dışındaki baca gazı arıtımından kaynaklanan çamurlar ve filtre kekleri",
    "101119": "101119 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren katı atıklar",
    "101120": "101120 | 10 11 19 dışındaki saha içi atıksu arıtımından kaynaklanan katı atıklar",
    "101199": "101199 | Başka bir şekilde tanımlanmamış atıklar",
    "1012": "1012 | Seramik Ürünler, Tuğlalar, Fayanslar ve İnşaat Malzemelerinin Üretiminden Kaynaklanan Atıklar",
    "101201": "101201 | Isıl işlem öncesi karışım hazırlama atıkları",
    "101203": "101203 | Partiküller ve toz",
    "101205": "101205 | Gaz arıtımından kaynaklanan çamurlar ve filtre kekleri ",
    "101206": "101206 | Iskarta kalıplar",
    "101208": "101208 | Atık seramikler, tuğlalar, fayanslar ve inşaat malzemeleri (ısıl işlem sonrası)",
    "101209": "101209 | Gaz arıtımından kaynaklanan tehlikeli maddeler içeren katı atıklar",
    "101210": "101210 | 10 12 09 dışındaki gaz arıtma katı atıkları",
    "101211": "101211 | Ağır metaller içeren sırlama atıkları",
    "101212": "101212 | 10 12 11 dışındaki sırlama atıkları",
    "101213": "101213 | Saha içi atıksu arıtımından kaynaklanan çamur",
    "101299": "101299 | Başka bir şekilde tanımlanmamış atıklar",
    "1013": "1013 | Çimento, Kireç ve Alçı ve Bunlardan Yapılan Ürünlerin Üretim Atıkları",
    "101301": "101301 | Isıl işlem öncesi karışım hazırlama atıkları",
    "101304": "101304 | Kirecin kalsinasyon ve hidratasyonundan kaynaklanan atıklar",
    "101306": "101306 | Partiküller ve toz (10 13 12 ve 10 13 13 hariç)",
    "101307": "101307 | Gaz arıtma çamuru ve filtre kekleri",
    "101309": "101309 | Asbestli çimento üretiminden kaynaklanan asbest içeren atıklar",
    "101310": "101310 | 10 13 09 dışındaki asbestli çimento üretimi atıkları",
    "101311": "101311 | 10 13 09 ve 10 13 10 dışındaki çimento bazlı kompozit malzeme üretim atıkları",
    "101312": "101312 | Gaz arıtımından kaynaklanan tehlikeli maddeler içeren katı atıklar",
    "101313": "101313 | 10 13 12 dışındaki gaz arıtma katı atıkları",
    "101314": "101314 | Atık beton ve beton çamurları",
    "101399": "101399 | Başka bir şekilde tanımlanmamış atıklar",
    "1014": "1014 | Krematoryum Atıkları",
    "101401": "101401 | Gaz temizlemeden kaynaklanan cıva içeren atıklar",
    "11": "11 | METAL VE DİĞER MALZEMELERİN KİMYASAL YÜZEY İŞLEMİ VE KAPLANMASI İŞLEMLERİNDEN KAYNAKLANAN ATIKLAR; DEMİR DIŞI HİDROMETALURJİ",
    "1101": "1101 | Metal ve Diğer Malzemelerin Kimyasal Yüzey İşlemi ve Kaplanmasından Kaynaklanan Atıklar (Örn: Galvanizleme, Çinko Kaplama, Dekapaj, Asitle Sıyırma, Fosfatlama, Alkalin Degradasyonu, Anotlama)",
    "110105": "110105 | Sıyırma asitleri (parlatma asitleri)",
    "110106": "110106 | Başka bir şekilde tanımlanmamış asitler",
    "110107": "110107 | Sıyırma bazları",
    "110108": "110108 | Fosfatlama çamurları",
    "110109": "110109 | Tehlikeli maddeler içeren çamurlar ve filtre kekleri",
    "110110": "110110 | 11 01 09 dışındaki çamurlar ve filtre kekleri",
    "110111": "110111 | Tehlikeli maddeler içeren sulu durulama sıvıları",
    "110112": "110112 | 11 01 11 dışındaki sulu durulama sıvıları",
    "110113": "110113 | Tehlikeli maddeler içeren yağ alma atıkları",
    "110114": "110114 | 11 01 13 dışındaki yağ alma atıkları",
    "110115": "110115 | Membran ya da iyon değişim sistemlerinden kaynaklanan tehlikeli maddeler içeren sıvı ve çamurlar",
    "110116": "110116 | Doymuş ya da bitik iyon değişim reçineleri",
    "110198": "110198 | Tehlikeli maddeler içeren diğer atıklar",
    "110199": "110199 | Başka bir şekilde tanımlanmamış atıklar",
    "1102": "1102 | Demir Dışındaki Madenlerin Hidrometalurjik İşlenmesinin Atıkları",
    "110202": "110202 | Çinko hidrometalurjisi (jarosid ve jeotid dahil) çamurları",
    "110203": "110203 | Sulu elektrolitik işlemleri için üretilen anot üretim atıkları",
    "110205": "110205 | Bakır hidrometalurjisi işlemlerinden kaynaklanan tehlikeli maddeler içeren atıklar",
    "110206": "110206 | 11 02 05 dışındaki bakır hidrometalurjisi atıkları",
    "110207": "110207 | Tehlikeli maddeler içeren diğer atıklar",
    "110299": "110299 | Başka bir şekilde tanımlanmamış atıklar",
    "1103": "1103 | Tavlama İşlemleri Çamurları ve Katı Maddeleri",
    "110301": "110301 | Siyanür içeren atıklar",
    "110302": "110302 | Diğer atıklar",
    "1105": "1105 | Sıcak Galvanizleme İşlemleri Atıkları",
    "110501": "110501 | Katı çinko",
    "110502": "110502 | Çinko külü",
    "110503": "110503 | Gaz arıtımından kaynaklanan katı atıklar",
    "110504": "110504 | Iskarta flaks malzemeler ",
    "110599": "110599 | Başka bir şekilde tanımlanmamış atıklar",
    "12": "12 | METALLERİN VE PLASTİKLERİN FİZİKİ VE MEKANİK YÜZEY İŞLEMLERİNDEN VE ŞEKİLLENDİRİLMESİNDEN KAYNAKLANAN ATIKLAR ",
    "1201": "1201 | Metallerin ve Plastiklerin Fiziki ve Mekanik Yüzey İşlemlerinden ve Biçimlendirilmesinden Kaynaklanan Atıklar ",
    "120101": "120101 | Demir metal çapakları ve talaşları",
    "120102": "120102 | Demir metal toz ve parçacıklar",
    "120103": "120103 | Demir dışı metal çapakları ve talaşları",
    "120104": "120104 | Demir dışı metal toz ve parçacıklar",
    "120105": "120105 | Plastik yongalar ve çapaklar",
    "120106": "120106 | Halojen içeren madeni bazlı işleme yağları (emülsiyon ve solüsyonlar hariç)",
    "120107": "120107 | Halojen içermeyen madeni bazlı işleme yağları (emülsiyon ve solüsyonlar hariç)",
    "120108": "120108 | Halojen içeren işleme emülsiyon ve solüsyonları",
    "120109": "120109 | Halojen içermeyen işleme emülsiyon ve solüsyonları",
    "120110": "120110 | Sentetik işleme yağları",
    "120112": "120112 | Kullanılmış (mum) parafin ve yağlar  ",
    "120113": "120113 | Kaynak atıkları",
    "120114": "120114 | Tehlikeli maddeler içeren işleme çamurları",
    "120115": "120115 | 12 01 14 dışındaki işleme çamurları",
    "120116": "120116 | Tehlikeli maddeler içeren kumlama maddeleri atıkları",
    "120117": "120117 | 12 01 16 dışındaki kumlama maddeleri atıkları",
    "120118": "120118 | Yağ içeren metalik çamurlar (öğütme, bileme ve freze tortuları)",
    "120119": "120119 | Biyolojik olarak kolay bozunur işleme yağı",
    "120120": "120120 | Tehlikeli maddeler içeren öğütme parçaları ve öğütme maddeleri",
    "120121": "120121 | 12 01 20 dışındaki öğütme parçaları ve öğütme maddeleri",
    "120199": "120199 | Başka bir şekilde tanımlanmamış atıklar",
    "1203": "1203 | Su ve Buhar Yağ Alma İşlemlerinden Kaynaklanan Atıklar (11 Hariç)",
    "120301": "120301 | Sulu yıkama sıvıları",
    "120302": "120302 | Buhar yağ alma atıkları",
    "13": "13 | YAĞ ATIKLARI VE SIVI YAKIT ATIKLARI (YENİLEBİLİR YAĞLAR, 05 VE 12 HARİÇ)",
    "1301": "1301 | Atık Hidrolik Yağlar",
    "130101": "130101 | PCB (2) içeren hidrolik yağlar",
    "130104": "130104 | Klor içeren emülsiyonlar",
    "130105": "130105 | Klor içermeyen emülsiyonlar",
    "130109": "130109 | Mineral esaslı klor içeren hidrolik yağlar",
    "130110": "130110 | Mineral esaslı klor içermeyen hidrolik yağlar",
    "130111": "130111 | Sentetik hidrolik yağlar",
    "130112": "130112 | Kolayca biyolojik olarak bozunabilir hidrolik yağlar",
    "130113": "130113 | Diğer hidrolik yağlar",
    "1302": "1302 | Atık Motor, Şanzıman ve Yağlama Yağları",
    "130204": "130204 | Mineral esaslı klor içeren motor, şanzıman ve yağlama yağları",
    "130205": "130205 | Mineral esaslı klor içermeyen motor, şanzıman ve yağlama yağları",
    "130206": "130206 | Sentetik motor, şanzıman ve yağlama yağları",
    "130207": "130207 | Kolayca biyolojik olarak bozunabilir motor, şanzıman ve yağlama yağları",
    "130208": "130208 | Diğer motor, şanzıman ve yağlama yağları",
    "1303": "1303 | Atık Yalıtım ve Isı İletim Yağları",
    "130301": "130301 | PCB’ler içeren yalıtım ya da ısı iletim yağları",
    "130306": "130306 | 13 03 01 dışındaki mineral esaslı klor içeren yalıtım ve ısı iletim yağları",
    "130307": "130307 | Mineral esaslı klor içermeyen yalıtım ve ısı iletim yağları  ",
    "130308": "130308 | Sentetik yalıtım ve ısı iletim yağları",
    "130309": "130309 | Kolayca biyolojik olarak bozunabilir yalıtım ve ısı iletim yağları",
    "130310": "130310 | Diğer yalıtım ve ısı iletim yağları",
    "1304": "1304 | Sintine Yağları",
    "130401": "130401 | Nehir ve göl seyrüseferinden (iç su yolu denizciliğinden) kaynaklanan sintine yağları",
    "130402": "130402 | İskele kanalizasyonlarından(mendirekten) kaynaklanan sintine yağları",
    "130403": "130403 | Diğer denizcilik seyrüseferinden kaynaklanan sintine yağları",
    "1305": "1305 | Yağ/Su Ayırıcısı İçerikleri",
    "130501": "130501 | Kum odacığından ve yağ/su ayırıcısından çıkan katılar",
    "130502": "130502 | Yağ/su ayırıcısından çıkan çamurlar",
    "130503": "130503 | Yakalayıcı (interseptör) çamurları",
    "130506": "130506 | Yağ/su ayırıcılarından çıkan yağ",
    "130507": "130507 | Yağ/su ayırıcılarından çıkan yağlı su",
    "130508": "130508 | Kum odacığından ve yağ/su ayırıcılarından çıkan karışık atıklar",
    "1307": "1307 | Sıvı Yakıtların Atıkları",
    "130701": "130701 | Fuel-oil ve mazot",
    "130702": "130702 | Benzin",
    "130703": "130703 | Diğer yakıtlar (karışımlar dahil)",
    "1308": "1308 | BAŞKA BİR ŞEKİLDE TANIMLANMAMIŞ YAĞ ATIKLARI",
    "130801": "130801 | Tuz giderim çamurları ya da emülsiyonları",
    "130802": "130802 | Diğer emülsiyonlar",
    "130899": "130899 | Başka bir şekilde tanımlanmamış atıklar",
    "14": "14 | ATIK ORGANİK ÇÖZÜCÜLER, SOĞUTUCULAR VE İTİCİ GAZLAR (07 VE 08 HARİÇ)",
    "1406": "1406 | Atık Organik Çözücüler, Soğutucular ve Köpük/Aerosol İtici Gazlar",
    "140601": "140601 | Kloroflorokarbonlar, HCFC, HFC",
    "140602": "140602 | Diğer halojenli çözücüler ve çözücü karışımları",
    "140603": "140603 | Diğer çözücüler ve çözücü karışımları",
    "140604": "140604 | Halojenli çözücüler içeren çamurlar veya katı atıklar",
    "140605": "140605 | Diğer çözücüleri içeren çamurlar veya katı atıklar",
    "15": "15 | ATIK AMBALAJLAR İLEBAŞKA BİR ŞEKİLDE BELİRTİLMEMİŞ EMİCİLER, SİLME BEZLERİ, FİLTRE MALZEMELERİ VE KORUYUCU GİYSİLER",
    "1501": "1501 | Ambalaj (Belediyenin Ayrı Toplanmış Ambalaj Atıkları Dahil)",
    "150101": "150101 | Kağıt ve karton ambalaj",
    "150102": "150102 | Plastik ambalaj",
    "150103": "150103 | Ahşap ambalaj",
    "150104": "150104 | Metalik ambalaj",
    "150105": "150105 | Kompozit ambalaj",
    "150106": "150106 | Karışık ambalaj",
    "150107": "150107 | Cam ambalaj",
    "150109": "150109 | Tekstil ambalaj",
    "150110": "150110 | Tehlikeli maddelerin kalıntılarını içeren ya da tehlikeli maddelerle kontamine olmuş ambalajlar",
    "150111": "150111 | Boş basınçlı konteynerler dahil olmak üzere tehlikeli gözenekli katı yapı (örneğin asbest) içeren metalik ambalajlar",
    "1502": "1502 | Emiciler, Filtre Malzemeleri, Temizleme Bezleri ve Koruyucu Giysiler",
    "150202": "150202 | Tehlikeli maddelerle kirlenmiş emiciler, filtre malzemeleri (başka şekilde tanımlanmamış ise yağ filtreleri), temizleme bezleri, koruyucu giysiler",
    "150203": "150203 | 15 02 02 dışındaki emiciler, filtre malzemeleri, temizleme bezleri, koruyucu giysiler",
    "16": "16 | LİSTEDE BAŞKA BİR ŞEKİLDE BELİRTİLMEMİŞ ATIKLAR",
    "1601": "1601 | Çeşitli Taşıma Türlerindeki (İş Makineleri Dahil) Ömrünü Tamamlamış Araçlar ve Ömrünü Tamamlamış Araçların Sökülmesi ile Araç Bakımından (13, 14, 16 06 ve 16 08 hariç) Kaynaklanan Atıklar",
    "160103": "160103 | Ömrünü tamamlamış lastikler",
    "160104": "160104 | Ömrünü tamamlamış araçlar ",
    "160106": "160106 | Sıvı ya da tehlikeli maddeler içermeyen ömrünü tamamlamış araçlar",
    "160107": "160107 | Yağ filtreleri",
    "160108": "160108 | Cıva içeren parçalar",
    "160109": "160109 | PCB içeren parçalar",
    "160110": "160110 | Patlayıcı parçalar (örneğin hava yastıkları)",
    "160111": "160111 | Asbest içeren fren balataları",
    "160112": "160112 | 16 01 11 dışındaki fren balataları",
    "160113": "160113 | Fren sıvıları",
    "160114": "160114 | Tehlikeli maddeler içeren antifriz sıvıları",
    "160115": "160115 | 16 01 14 dışındaki antifriz sıvıları",
    "160116": "160116 | Sıvılaştırılmış gaz tankları",
    "160117": "160117 | Demir metaller",
    "160118": "160118 | Demir olmayan metaller",
    "160119": "160119 | Plastik",
    "160120": "160120 | Cam",
    "160121": "160121 | 16 01 07’den 16 01 11’e ve 16 01 13 ile 16 01 14 dışındaki tehlikeli parçalar",
    "160122": "160122 | Başka bir şekilde tanımlanmamış parçalar",
    "160199": "160199 | Başka bir şekilde tanımlanmamış atıklar",
    "1602": "1602 | Elektrikli ve Elektronik Ekipman Atıkları",
    "160209": "160209 | PCB’ler içeren transformatörler ve kapasitörler",
    "160210": "160210 | 16 02 09 dışındaki PCB içeren ya da PCB ile kontamine olmuş ıskarta ekipmanlar",
    "160211": "160211 | Kloroflorokarbon, HCFC, HFC içeren ıskarta ekipmanlar",
    "160212": "160212 | Serbest asbest içeren ıskarta ekipman",
    "160213": "160213 | 16 02 09’dan 16 02 12’ye kadar olanların dışındaki tehlikeli parçalar (3) içeren ıskarta ekipmanlar",
    "160214": "160214 | 16 02 09’dan 16 02 13’e kadar olanların dışındaki ıskarta ekipmanlar",
    "160215": "160215 | Iskarta ekipmanlardan çıkartılmış tehlikeli parçalar  ",
    "160216": "160216 | 16 02 15 dışındaki ıskarta ekipmanlardan çıkartılmış parçalar",
    "1603": "1603 | Standart Dışı Gruplar ve Kullanılmamış Ürünler",
    "160303": "160303 | Tehlikeli maddeler içeren inorganik atıklar",
    "160304": "160304 | 16 03 03 dışındaki inorganik atıklar",
    "160305": "160305 | Tehlikeli maddeler içeren organik atıklar",
    "160306": "160306 | 16 03 05 dışındaki organik atıklar",
    "160307": "160307 | Metalik cıva",
    "1604": "1604 | Patlayıcı Atıklar",
    "160401": "160401 | Mühimmat Atığı",
    "160402": "160402 | Havai fişek atıkları",
    "160403": "160403 | Diğer patlayıcı atıklar",
    "1605": "1605 | Basınçlı Tank İçindeki Gazlar ve Iskartaya Çıkmış Kimyasallar",
    "160504": "160504 | Basınçlı tanklar içinde tehlikeli maddeler içeren gazlar (halonlar dahil)",
    "160505": "160505 | 16 05 04 dışında basınçlı tanklar içindeki gazlar",
    "160506": "160506 | Laboratuvar kimyasalları karışımları dahil tehlikeli maddelerden oluşan ya da tehlikeli maddeler içeren laboratuvar kimyasalları",
    "160507": "160507 | Tehlikeli maddeler içeren ya da bunlardan oluşan ıskarta inorganik kimyasallar",
    "160508": "160508 | Tehlikeli maddeler içeren ya da bunlardan oluşan ıskarta organik kimyasallar",
    "160509": "160509 | 16 05 06, 16 05 07 ya da 16 05 08 dışındaki ıskarta kimyasallar",
    "1606": "1606 | Piller ve Akümülatörler",
    "160601": "160601 | Kurşunlu piller ve akümülatörler",
    "160602": "160602 | Nikel kadmiyum piller",
    "160603": "160603 | Cıva içeren piller",
    "160604": "160604 | Alkali piller (16 06 03 hariç)",
    "160605": "160605 | Diğer piller ve akümülatörler",
    "160606": "160606 | Piller ve akümülatörlerden ayrı toplanmış elektrolitler",
    "1607": "1607 | Nakliye Tankı, Depolama Tankı ve Varil Temizleme İşlemlerinden Kaynaklanan Atıklar (05 ve 13 hariç)",
    "160708": "160708 | Yağ içeren atıklar",
    "160709": "160709 | Diğer tehlikeli maddeler içeren atıklar",
    "160799": "160799 | Başka bir şekilde tanımlanmamış atıklar",
    "1608": "1608 | Bitik Katalizörler",
    "160801": "160801 | Altın, gümüş, renyum, rodyum, paladyum, iridyum ya da platin içeren bitik katalizörler (16 08 07 hariç)",
    "160802": "160802 | Tehlikeli geçiş metalleri (4) ya da tehlikeli geçiş metal bileşenlerini içeren bitik katalizörler",
    "160803": "160803 | Başka bir şekilde tanımlanmamış ara metaller ve ara metal bileşenleri içeren bitik katalizörler ",
    "160804": "160804 | Bitik katalitik “cracking” katalizör sıvısı (16 08 07 hariç)  ",
    "160805": "160805 | Fosforik asit içeren bitik katalizörler",
    "160806": "160806 | Katalizör olarak bitik sıvılar",
    "160807": "160807 | Tehlikeli maddelerle kontamine olmuş bitik katalizörler  ",
    "1609": "1609 | Oksitleyici Maddeler",
    "160901": "160901 | Permanganatlar (örneğin potasyum permanganat)",
    "160902": "160902 | Kromatlar (örneğin potasyum kromat, potasyum veya sodyum dikromat)",
    "160903": "160903 | Peroksitler(örneğin hidrojen peroksit)",
    "160904": "160904 | Başka bir şekilde tanımlanmamış oksitleyici malzemeler",
    "1610": "1610 | Saha Dışı Arıtmaya Gönderilecek Sulu Sıvı Atıklar",
    "161001": "161001 | Tehlikeli maddeler içeren sulu sıvı atıklar",
    "161002": "161002 | 16 10 01 dışındaki sulu sıvı atıklar",
    "161003": "161003 | Tehlikeli madde içeren sulu derişik maddeler",
    "161004": "161004 | 16 10 03 dışındaki sulu derişik maddeler",
    "1611": "1611 | Atık Astarlar ve Refraktörler",
    "161101": "161101 | Metalürjik proseslerden kaynaklanan, tehlikeli maddeler içeren karbon bazlı astarlar ve refraktörler",
    "161102": "161102 | 16 11 01 dışındaki metalürjik proseslerden kaynaklanan karbon bazlı astar ve refraktörler",
    "161103": "161103 | Metalürjik proseslerden kaynaklanan, tehlikeli maddeler içeren diğer astarlar ve refraktörler",
    "161104": "161104 | 16 11 03 dışındaki metalürjik proseslerden kaynaklanan diğer astar ve refraktörler",
    "161105": "161105 | Metalürjik olmayan proseslerden kaynaklanan, tehlikeli maddeler içeren astarlar ve refraktörler",
    "161106": "161106 | 16 11 05 dışındaki metalürjik olmayan proseslerden kaynaklanan astar ve refraktörler",
    "17": "17 | İNŞAAT VE YIKINTI ATIKLARI (KİRLENMİŞ ALANLARDAN ÇIKARTILAN HAFRİYAT DAHİL)",
    "1701": "1701 | Beton, Tuğla, Kiremit ve Seramik",
    "170101": "170101 | Beton",
    "170102": "170102 | Tuğlalar",
    "170103": "170103 | Kiremitler ve seramikler",
    "170106": "170106 | Tehlikeli maddeler içeren beton, tuğla, kiremit ve seramik karışımları ya da ayrılmış grupları",
    "170107": "170107 | 17 01 06 dışındaki beton, tuğla kiremit ve seramik karışımları ya da ayrılmış grupları",
    "1702": "1702 | Ahşap, Cam ve Plastik",
    "170201": "170201 | Ahşap",
    "170202": "170202 | Cam",
    "170203": "170203 | Plastik",
    "170204": "170204 | Tehlikeli maddeler içeren ya da tehlikeli maddelerle kontamine olmuş ahşap, cam ve plastik",
    "1703": "1703 | Bitümlü Karışımlar, Kömür Katranı ve Katranlı Ürünler",
    "170301": "170301 | Kömür katranı içeren bitümlü karışımlar",
    "170302": "170302 | 17 03 01 dışındaki bitümlü karışımlar",
    "170303": "170303 | Kömür katranı ve katranlı ürünler",
    "1704": "1704 | Metaller (Alaşımları Dahil)",
    "170401": "170401 | Bakır, bronz, pirinç ",
    "170402": "170402 | Alüminyum",
    "170403": "170403 | Kurşun",
    "170404": "170404 | Çinko",
    "170405": "170405 | Demir ve çelik",
    "170406": "170406 | Kalay",
    "170407": "170407 | Karışık metaller",
    "170409": "170409 | Tehlikeli maddelerle kontamine olmuş metal atıkları",
    "170410": "170410 | Yağ, katran ve diğer tehlikeli maddeler içeren kablolar",
    "170411": "170411 | 17 04 10 dışındaki kablolar",
    "1705": "1705 | Toprak (Kirlenmiş Yerlerde Yapılan Hafriyat Dahil), Taşlar ve Dip Tarama Çamurları",
    "170503": "170503 | Tehlikeli maddeler içeren toprak ve taşlar",
    "170504": "170504 | 17 05 03 dışındaki toprak ve taşlar",
    "170505": "170505 | Tehlikeli maddeler içeren dip tarama çamuru",
    "170506": "170506 | 17 05 05 dışındaki dip tarama çamuru",
    "170507": "170507 | Tehlikeli maddeler içeren demiryolu çakılı",
    "170508": "170508 | 17 05 07 dışındaki demiryolu çakılı",
    "1706": "1706 | Yalıtım Malzemeleri ve Asbest İçeren İnşaat Malzemeleri",
    "170601": "170601 | Asbest içeren yalıtım malzemeleri",
    "170603": "170603 | Tehlikeli maddelerden oluşan ya da tehlikeli maddeler içeren diğer yalıtım malzemeleri",
    "170604": "170604 | 17 06 01 ve 17 06 03 dışındaki yalıtım malzemeleri",
    "170605": "170605 | Asbest içeren inşaat malzemeleri ",
    "1708": "1708 | Alçı Bazlı İnşaat Malzemeleri",
    "170801": "170801 | Tehlikeli maddeler ile kontamine olmuş alçı bazlı inşaat malzemeleri",
    "170802": "170802 | 17 08 01 dışındaki alçı bazlı inşaat malzemeleri",
    "1709": "1709 | Diğer İnşaat ve Yıkıntı Atıkları",
    "170901": "170901 | Cıva içeren inşaat ve yıkıntı atıkları",
    "170902": "170902 | PCB içeren inşaat ve yıkıntı atıkları (örneğin PCB içeren dolgu macunları, PCB içeren reçine bazlı taban kaplama malzemeleri, PCB içeren kaplanmış sırlama birimleri, PCB içeren kapasitörler) ",
    "170903": "170903 | Tehlikeli maddeler içeren diğer inşaat ve yıkıntı atıkları (karışık atıklar dahil)",
    "170904": "170904 | 17 09 01, 17 09 02 ve 17 09 03 dışındaki karışık inşaat ve yıkıntı atıkları",
    "18": "18 | İNSAN VE HAYVAN SAĞLIĞI VE/VEYA BU KONULARDAKİ ARAŞTIRMALARDAN KAYNAKLANAN ATIKLAR (DOĞRUDAN SAĞLIĞA İLİŞKİN OLMAYAN MUTFAK VE RESTORAN ATIKLARI HARİÇ)",
    "1801": "1801 | İnsanlarda Doğum, Teşhis, Tedavi ya da Hastalık Önleme Çalışmalarından Kaynaklanan Atıklar",
    "180101": "180101 | Kesiciler (18 01 03 hariç)",
    "180102": "180102 | Kan torbaları ve kan yedekleri dahil vücut parçaları ve organları (18 01 03 hariç)",
    "180103": "180103 | Enfeksiyonu önlemek amacı ile toplanmaları ve bertarafı özel işleme tabi olan atıklar",
    "180104": "180104 | Enfeksiyonu önlemek amacı ile toplanmaları ve bertarafı özel işleme tabi olmayan atıklar (örneğin sargılar, vücut alçıları, tek kullanımlık giysiler, alt bezleri)",
    "180106": "180106 | Tehlikeli maddeler içeren ya da tehlikeli maddelerden oluşan kimyasallar",
    "180107": "180107 | 18 01 06 dışındaki kimyasallar",
    "180108": "180108 | Sitotoksik ve sitostatik ilaçlar  ",
    "180109": "180109 | 18 01 08 dışındaki ilaçlar",
    "180110": "180110 | Diş tedavisinden kaynaklanan amalgam atıkları",
    "1802": "1802 | Hayvanlarla İlgili Araştırma, Teşhis, Tedavi ya da Hastalık Önleme Çalışmalarından Kaynaklanan Atıklar",
    "180201": "180201 | Kesiciler (18 02 02 hariç) ",
    "180202": "180202 | Enfeksiyonu önlemek amacı ile toplanmaları ve bertarafı özel işleme tabi olan atıklar",
    "180203": "180203 | Enfeksiyonu önlemek amacı ile toplanmaları ve bertarafı özel işleme tabi olmayan atıklar",
    "180205": "180205 | Tehlikeli maddeler içeren ya da tehlikeli maddelerden oluşan kimyasallar",
    "180206": "180206 | 18 02 05 dışındaki kimyasallar",
    "180207": "180207 | Sitotoksik ve sitostatik ilaçlar",
    "180208": "180208 | 18 02 07 dışındaki ilaçlar",
    "19": "19 | ATIK YÖNETİM TESİSLERİNDEN, TESİS DIŞI ATIKSU ARITMA TESİSLERİNDEN VE İNSAN TÜKETİMİ VE ENDÜSTRİYEL KULLANIM İÇİN SU HAZIRLAMA TESİSLERİNDEN KAYNAKLANAN ATIKLAR",
    "1901": "1901 | Atık Yakma veya Piroliz’den Kaynaklanan Atıklar",
    "190102": "190102 | Taban külünden ayrılan demir içerikli maddeler",
    "190105": "190105 | Gaz arıtımından kaynaklanan filtre kekleri ",
    "190106": "190106 | Gaz arıtımından kaynaklanan sulu sıvı atıklar ile diğer sulu sıvı atıkları",
    "190107": "190107 | Gaz arıtımından kaynaklanan katı atıklar",
    "190110": "190110 | Baca gazı arıtımından kaynaklanan kullanılmış aktif karbon",
    "190111": "190111 | Tehlikeli maddeler içeren taban külü ve cüruf ",
    "190112": "190112 | 19 01 11 dışındaki taban külü ve cüruf",
    "190113": "190113 | Tehlikeli maddeler içeren uçucu kül ",
    "190114": "190114 | 19 01 13 dışındaki uçucu kül",
    "190115": "190115 | Tehlikeli maddeler içeren kazan tozu",
    "190116": "190116 | 19 01 15 dışındaki kazan tozu",
    "190117": "190117 | Tehlikeli maddeler içeren piroliz atıkları",
    "190118": "190118 | 19 01 17 dışındaki piroliz atıkları",
    "190119": "190119 | Akışkan yatak kumları",
    "190199": "190199 | Başka bir şekilde tanımlanmamış atıklar",
    "1902": "1902 | Atıkların Fiziki/Kimyasal Arıtımından Kaynaklanan Atıklar (Krom Giderme, Siyanür Giderme, Nötralizasyon Dahil) ",
    "190203": "190203 | Tehlikeli olmayan atıkların önceden karıştırılması ile oluşmuş atıklar",
    "190204": "190204 | En az bir tehlikeli atık ile önceden karıştırılması ile oluşmuş atıklar",
    "190205": "190205 | Fiziksel ve kimyasal işlemlerden kaynaklanan tehlikeli maddeler içeren çamurlar",
    "190206": "190206 | 19 02 05 dışındaki fiziksel ve kimyasal işlemlerden kaynaklanan çamurlar",
    "190207": "190207 | Ayrışmadan oluşan yağ ve konsantrasyonlar",
    "190208": "190208 | Tehlikeli maddeler içeren sıvı yanabilir atıklar",
    "190209": "190209 | Tehlikeli maddeler içeren katı yanabilir atıklar",
    "190210": "190210 | 19 02 08 ve 19 02 09 dışında yanabilir atıklar",
    "190211": "190211 | Tehlikeli maddeler içeren diğer atıklar",
    "190299": "190299 | Başka bir şekilde tanımlanmamış atıklar",
    "1903": "1903 | Stabilize Edilmiş/Katılaştırılmış Atıklar (5)",
    "190304": "190304 | 19 03 08 dışındaki tehlikeli olarak işaretlenmiş kısmen (6) stabilize olmuş atıklar",
    "190305": "190305 | 19 03 04 dışındaki stabilize olmuş atıklar",
    "190306": "190306 | Tehlikeli olarak sınıflandırılmış, katılaştırılmış atıklar",
    "190307": "190307 | 19 03 06 dışındaki katılaştırılmış atıklar",
    "190308": "190308 | Kısmen stabilize olmuş cıva",
    "1904": "1904 | Vitrifiye Edilmiş Atık ve Vitrifikasyon İşleminden Kaynaklanan Atıklar",
    "190401": "190401 | Vitrifiye edilmiş atıklar",
    "190402": "190402 | Uçucu kül ve diğer baca gazı arıtma atıkları",
    "190403": "190403 | Vitrifiye olmamış katılar",
    "190404": "190404 | Vitrifiye atık tavlanmasından çıkan sulu sıvı",
    "1905": "1905 | Katı Atıkların Aerobik Arıtımından Kaynaklanan Atıklar",
    "190501": "190501 | Belediye ve benzeri atıklarının kompostlanmamış fraksiyonları",
    "190502": "190502 | Hayvansal ve bitkisel atıklarının kompostlanmamış fraksiyonları",
    "190503": "190503 | Standart dışı kompost (8)",
    "190599": "190599 | Başka bir şekilde tanımlanmamış atıklar",
    "1906": "1906 | Atığın Anaerobik Arıtımından Kaynaklanan Atıklar",
    "190603": "190603 | Belediye atıklarının anaerobik arıtımından kaynaklanan sıvılar",
    "190604": "190604 | Belediye atıklarının anaerobik arıtımından kaynaklanan posalar (8)",
    "190605": "190605 | Hayvansal ve bitkisel atıkların anaerobik arıtımından kaynaklanan sıvılar",
    "190606": "190606 | Hayvansal ve bitkisel atıklarını anaerobik arıtımından kaynaklanan posalar (8)",
    "190699": "190699 | Başka bir şekilde tanımlanmamış atıklar",
    "1907": "1907 | Düzenli Depolama Sahası Sızıntı Suları",
    "190702": "190702 | Tehlikeli maddeler içeren düzenli depolama sahası sızıntı suları",
    "190703": "190703 | 19 07 02 dışındaki düzenli depolama sahası sızıntı suları",
    "1908": "1908 | Başka Bir Şekilde Tanımlanmamış Atıksu Arıtma Tesisi Atıkları",
    "190801": "190801 | Elek üstü maddeler",
    "190802": "190802 | Kum ayırma işleminden kaynaklanan atıkları",
    "190805": "190805 | Kentsel atıksuyun arıtılmasından kaynaklanan çamurlar (8)",
    "190806": "190806 | Doymuş ya da kullanılmış iyon değiştirici reçineler",
    "190807": "190807 | İyon değiştiricilerinin rejenerasyonundan kaynaklanan solüsyonlar ve çamurlar",
    "190808": "190808 | Ağır metaller içeren membran sistemi atıkları",
    "190809": "190809 | Yağ ve su ayrışmasından kaynaklanan sadece yenilebilir yağlar içeren yağ karışımları ve gres",
    "190810": "190810 | 19 08 09 dışındaki yağ ve su ayrışmasından çıkan yağ karışımları ve gres",
    "190811": "190811 | Endüstriyel atıksuyun biyolojik arıtılmasından kaynaklanan tehlikeli maddeler içeren çamurlar  ",
    "190812": "190812 | 19 08 11 dışındaki endüstriyel atıksuyun biyolojik arıtılmasından kaynaklanan çamurlar (8)",
    "190813": "190813 | Endüstriyel atıksuyun diğer yöntemlerle arıtılmasından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "190814": "190814 | 19 08 13 dışındaki endüstriyel atıksuyun diğer yöntemlerle arıtılmasından kaynaklanan çamurlar (8)",
    "190899": "190899 | Başka bir şekilde tanımlanmamış atıklar",
    "1909": "1909 | İnsan Tüketimi ve Endüstriyel Kullanım İçin Gereken Suyun Hazırlanmasından Kaynaklanan Atıklar",
    "190901": "190901 | İlk filtreleme ve süzme işlemlerinden kaynaklanan katı atıklar ",
    "190902": "190902 | Su berraklaştırılmasından kaynaklanan çamurlar",
    "190903": "190903 | Karbonat gidermeden kaynaklanan çamurlar",
    "190904": "190904 | Kullanılmış aktif karbon",
    "190905": "190905 | Doymuş ya da kullanılmış iyon değiştirme reçinesi",
    "190906": "190906 | İyon değiştiricilerinin rejenerasyonundan kaynaklanan solüsyonlar ve çamurlar",
    "190999": "190999 | Başka bir şekilde tanımlanmamış atıklar",
    "1910": "1910 | Metal İçeren Atıkların Parçalanmasından Kaynaklanan Atıklar",
    "191001": "191001 | Demir ve çelik atıkları",
    "191002": "191002 | Demir olmayan atıklar",
    "191003": "191003 | Tehlikeli maddeler içeren uçucu atık parçacıkları ve tozlar",
    "191004": "191004 | 19 10 03 dışındaki uçucu atık parçacıkları ve tozlar",
    "191005": "191005 | Tehlikeli maddeler içeren diğer kalıntılar",
    "191006": "191006 | 19 10 05 dışındaki diğer kalıntılar",
    "1911": "1911 | Yağın Yeniden Üretiminden Kaynaklanan Atıklar",
    "191101": "191101 | Kullanılmış filtre killeri",
    "191102": "191102 | Asit katranları",
    "191103": "191103 | Sulu sıvı atıklar",
    "191104": "191104 | Yakıtların bazlarla temizlenmesinden kaynaklanan atıklar",
    "191105": "191105 | Saha içi atıksu arıtımından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "191106": "191106 | 19 11 05 dışındaki saha içi atıksu arıtımından kaynaklanan çamurlar",
    "191107": "191107 | Baca gazı temizleme atıkları",
    "191199": "191199 | Başka bir şekilde tanımlanmamış atıklar",
    "1912": "1912 | Başka Bir Şekilde Tanımlanmamış Atıkların Mekanik Arıtımından (Örneğin Ayrıştırılması, Ezilmesi, Sıkıştırılması, Topak Haline Getirilmesi) Kaynaklanan Atıklar",
    "191201": "191201 | Kağıt ve karton",
    "191202": "191202 | Demir metali",
    "191203": "191203 | Demir dışı metal",
    "191204": "191204 | Plastik ve lastik",
    "191205": "191205 | Cam",
    "191206": "191206 | Tehlikeli maddeler içeren ahşap",
    "191207": "191207 | 19 12 06 dışındaki ahşap",
    "191208": "191208 | Tekstil malzemeleri",
    "191209": "191209 | Mineraller (örneğin kum, taşlar)",
    "191210": "191210 | Yanabilir atıklar (atıktan türetilmiş yakıt)",
    "191211": "191211 | Atıkların mekanik işlenmesinden kaynaklanan tehlikeli maddeler içeren diğer atıklar (karışık malzemeler dahil)",
    "191212": "191212 | 19 12 11 dışında atıkların mekanik işlenmesinden kaynaklanan diğer atıklar (karışık malzemeler dahil)",
    "1913": "1913 | Toprak ve Yeraltı Suyu Islahından Kaynaklanan Atıklar",
    "191301": "191301 | Toprak ıslahından kaynaklanan tehlikeli maddeler içeren atıklar",
    "191302": "191302 | 19 13 01 dışında toprak ıslahından kaynaklanan atıklar",
    "191303": "191303 | Toprak ıslahından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "191304": "191304 | 19 13 03 dışındaki toprak ıslahından kaynaklanan çamurlar",
    "191305": "191305 | Yeraltı suyunun ıslahından kaynaklanan tehlikeli maddeler içeren çamurlar",
    "191306": "191306 | 19 13 05 dışındaki yeraltı suyunun ıslahından kaynaklanan çamurlar",
    "191307": "191307 | Yeraltı suyunun ıslahından kaynaklanan tehlikeli maddeler içeren sulu sıvı atıklar ve sulu konsantrasyonlar",
    "191308": "191308 | 19 13 07 dışındaki yeraltı suyunun ıslahından kaynaklanan sulu sıvı atıklar ve sulu konsantrasyonlar",
    "20": "20 | AYRI TOPLANMIŞ FRAKSİYONLAR DAHİL BELEDİYE ATIKLARI (EVLERDEN KAYNAKLANAN VE BENZER TİCARİ, ENDÜSTRİYEL VE KURUMSAL ATIKLAR) ",
    "2001": "2001 | Ayrı Toplanan Fraksiyonlar (15 01 Hariç)",
    "200101": "200101 | Kâğıt ve karton",
    "200102": "200102 | Cam",
    "200108": "200108 | Biyolojik olarak bozunabilir mutfak ve kantin atıkları (8)",
    "200110": "200110 | Giysiler",
    "200111": "200111 | Tekstil ürünleri",
    "200113": "200113 | Çözücüler",
    "200114": "200114 | Asitler",
    "200115": "200115 | Alkalinler",
    "200117": "200117 | Foto kimyasallar",
    "200119": "200119 | Pestisitler",
    "200121": "200121 | Flüoresan lambalar ve diğer cıva içeren atıklar",
    "200123": "200123 | Kloroflorokarbonlar içeren ıskartaya çıkartılmış ekipmanlar",
    "200125": "200125 | Yenilebilir sıvı ve katı yağlar (8)",
    "200126": "200126 | 20 01 25 dışındaki sıvı ve katı yağlar",
    "200127": "200127 | Tehlikeli maddeler içeren boya, mürekkepler, yapıştırıcılar ve reçineler",
    "200128": "200128 | 20 01 27 dışındaki boya, mürekkepler, yapıştırıcılar ve reçineler",
    "200129": "200129 | Tehlikeli maddeler içeren deterjanlar",
    "200130": "200130 | 20 01 29 dışındaki deterjanlar",
    "200131": "200131 | Sitotoksik ve sitostatik ilaçlar  ",
    "200132": "200132 | 20 01 31 dışındaki ilaçlar",
    "200133": "200133 | 16 06 01, 16 06 02 veya 16 06 03’un altında geçen pil ve akümülatörler ve bu pilleri içeren sınıflandırılmamış karışık pil ve akümülatörler",
    "200134": "200134 | 20 01 33 dışındaki pil ve akümülatörler",
    "200135": "200135 | 20 01 21 ve 20 01 23 dışındaki tehlikeli parçalar (7) içeren ve ıskartaya çıkmış elektrikli ve elektronik ekipmanlar",
    "200136": "200136 | 20 01 21, 20 01 23 ve 20 01 35 dışındaki ıskarta elektrikli ve elektronik ekipmanlar",
    "200137": "200137 | Tehlikeli maddeler içeren ahşap",
    "200138": "200138 | 20 01 37 dışındaki ahşap (1) (8)",
    "200139": "200139 | Plastikler",
    "200140": "200140 | Metaller",
    "200141": "200141 | Baca temizliğinden kaynaklanan atıklar",
    "200199": "200199 | Başka bir şekilde tanımlanmamış fraksiyonlar",
    "2002": "2002 | Bahçe ve Park Atıkları (Mezarlık Atıkları Dahil)",
    "200201": "200201 | Biyolojik olarak bozunabilir atıklar (8)",
    "200202": "200202 | Toprak ve taşlar",
    "200203": "200203 | Biyolojik olarak bozunamayan diğer atıklar",
    "2003": "2003 | Diğer Belediye Atıkları",
    "200301": "200301 | Karışık belediye atıkları (8)",
    "200302": "200302 | Pazarlardan kaynaklanan atıklar (8)",
    "200303": "200303 | Sokak temizleme kalıntıları",
    "200304": "200304 | Fosseptik çamurları (8)",
    "200306": "200306 | Kanalizasyon temizliğinden kaynaklanan atıklar (8)",
    "200307": "200307 | Hacimli atıklar",
    "200399": "200399 | Başka bir şekilde tanımlanmamış belediye atıkları",

}
