import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/AuthProvider";
import AuthService from '../services/AuthService';
import './LoginComponent.css';

const logos = [
  { src: '/images/carbon-accounting-svgrepo-com.svg', alt: 'Carbon' },
  { src: '/images/digital-id-svgrepo-com.svg', alt: 'Digital' },
  { src: '/images/earth-9-svgrepo-com.svg', alt: 'Earth' },
  { src: '/images/recycle-svgrepo-com.svg', alt: 'Recycle' },
  { src: '/images/recycling-water-svgrepo-com.svg', alt: 'Water' },
];

const LoginComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setToken } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await AuthService.login(email, password);
      if (response.data.accessToken) {
        setToken(response.data.accessToken);
        navigate("/dashboard", { replace: true });
      } else {
        setToken(null);
        console.error('Login succeeded but no token received');
      }
    } catch (error) {
      setToken(null);
      console.error('Login failed:', error);
    }
  };

  const routeToSignUp = (e) => {
    e.preventDefault();
    navigate("https://rens.co/demo-request", { replace: false });
  };

  return (
    <div className="login-container">
      <div className="background">
        {logos.map((logo, index) => (
          <div key={index} className={`icon-holder icon-holder${index + 1}`}>
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
      <div className="semi-transparent-container"></div>
      <div className="login-card">
        <img src="/images/rens_logo.svg" alt="Your logo" className="logo" />
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              placeholder="username@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Şifre</label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <a href="#" className="forgot-password">Şifrenizi unuttunuz mu?</a>
          <button type="submit" className="hero-button">Giriş Yap</button>
        </form>
        <p className="register-prompt">
          Henüz hesabınız yok mu? <a href="https://rens.co/demo-request" >Deneme sürümü talep et</a>
        </p>
      </div>
    </div>
  );
};

export default LoginComponent;