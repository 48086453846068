import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/AuthProvider";
import {
  Grid,
  IconButton,
  Pagination,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { FilterList, Add } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import SearchComponent from '../components/SearchComponent';
import styles from './MarketPlace.module.css';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  borderRadius: '16px',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: '16px 16px 0 0',
  width: '100%',
  height: '120px',
  objectFit: 'cover',
  filter: 'brightness(0.8)',
  opacity: 0.9,
}));


const MarketPlace = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState({
    category1: '',
    category2: '',
    category3: '',
    code: '',
    title: '',
    page: 1,
    limit: 15,
    sort: 'newest'
  });
  const { token } = useAuth();
  const [showFilter, setShowFilter] = useState(true);
  const [myItems, setMyItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [userName, setUserName] = useState('');
  const [openProgress, setOpenProgress] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  const fetchItems = async (filter) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listItem`, {
        headers: { 'x-access-token': token },
        params: filter,
      });
      setItems(response.data.data);
      setOpenProgress(false);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const fetchMyItems = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listItem/myItems`, {
            headers: {
                'x-access-token': token,
            },
        });
        setMyItems(response.data.data);
    } catch (error) {
        console.error('Error fetching user items:', error);
    }
  };

  const fetchProfile = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
            headers: {
                'x-access-token': token,
            },
        });
        setUserName(response.data.user.name);
    } catch (error) {
        console.error('Error fetching user items:', error);
    }
  };

  useEffect(() => {
    setOpenProgress(true);
    fetchProfile();
    fetchItems(filter);
    fetchMyItems();
    setPageCount(items.length <= 15 ? 1 : Math.ceil(items.length / 15));
  }, [searchClicked]);

  const handleFilterChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value, page: 1 });
  };

  const handlePageChange = (event, page) => {
    setFilter({ ...filter, page });
  };

  const handleItemClick = (itemId) => {
    const foundItem = myItems.find(item => item.id === itemId);
    navigate(`/items/${itemId}`, { state: { isMyItem: foundItem ? true : false } });
  };

  const toggleFilter = () => {
    setShowFilter(prevState => {
      setFilter(prevFilter => ({ ...prevFilter, page: 1 }));
      return !prevState;
    });
  };

  const handleCreateItem = () => {
    navigate('/create-listing');
  };

  
  if (openProgress) return (
    <div 
        className="flex justify-center items-center h-screen" 
        style={{ backgroundColor: 'rgba(211, 211, 211, 0.4)' }}
    >
        <div 
            className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2" 
            style={{ borderColor: '#0F4A46' }}
        ></div>
    </div>
  );

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div className="ml-64" style={{ flex: 1 }}>
        <Header userName={userName}/>
        <Box sx={{ p: 4 }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <div className={styles.dashboardContent}>
              <div className={styles.stepHeader}>
                <img src="/rensmarketlogo.png" alt="Rens Market Logo" className={styles.stepLogo} />
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleCreateItem}
                    sx={{
                      backgroundColor: '#165853',
                      height: '32px',
                      padding: '4px 8px',
                      borderRadius: '16px',
                      fontSize: '0.75rem',
                      ml: 'auto',
                      '&:hover': {
                        backgroundColor: '#165853',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    İlan Oluştur
                </Button>
              </div>
              <div className={styles.stepContainer}>
                <div className="flex gap-2 p-4 bg-[#0F4A46] rounded-2xl items-center">
                  {showFilter && (
                        <SearchComponent filter={filter} setFilter={setFilter} searchClicked ={searchClicked} setSearchClicked={setSearchClicked} />
                      )}
                    <IconButton onClick={toggleFilter} color="white" sx={{ ml: 'auto', color: '#FFFFFF'}}>
                      <FilterList />
                    </IconButton>
                  </div>
              </div>
              <div className={styles.stepContainer}>
                <div className="bg-[#0F4A46] rounded-2xl p-6">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {items.map((item) => (
                        <Grid item key={item.id} xs={12} sm={6} md={2.4}>
                          <StyledCard onClick={() => handleItemClick(item.id)} sx={{ m: 1 }}>
                            <StyledCardMedia
                              component="img"
                              image={item.ItemPhotoHistories[0].source_url}
                              alt={item.title}
                            />
                            <CardContent sx={{ p: 1 }}>
                              <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '0.875rem' }}>
                                {item.title || 'Untitled Item'}
                              </Typography>
                              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" color="#247b0b" sx={{ fontSize: '0.875rem' }}>
                                  {item.amount} {item.unit}
                                </Typography>
                              </Box>
                            </CardContent>
                          </StyledCard>
                        </Grid>
                      ))}
                    </Grid>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                      <Pagination
                        count={pageCount}
                        page={filter.page}
                        onChange={handlePageChange}
                        size="medium"
                        sx={{
                          '& .MuiPaginationItem-root': {
                            color: '#FFFFFF',
                          },
                          '& .Mui-selected': {
                            backgroundColor: '#165853 !important',
                            color: '#FFFFFF !important',
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default MarketPlace;
