import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ProtectedRoute} from "./ProtectedRoute";
import {useAuth} from "../contexts/AuthProvider";
import LoginComponent from "../pages/LoginPage";
import SignUpComponent from "../pages/SignUpPage";
import React from "react";
import MarketPlace from "../pages/MarketPlacePage";
import ItemDetailComponent from "../components/ItemDetailComponent";
import ProfilePage from "../pages/ProfilePage";
import RENSCO2Page from "../pages/RENSCO2Page";
import OffersPage from "../pages/OffersPage";
import MyListingsPage from "../pages/MyListingsPage";
import CreateListItemFormComponent from "../components/CreateListItemFormComponent";
import RensDashboard from "../pages/RensDashboard";

const Routes = () => {
    const { token } = useAuth();

    const routesForPublic = [
        {
            path: "/",
            element: <div><LoginComponent/></div>,
        },
        {
            path: "/login",
            element: <div><LoginComponent/></div>,
        },
        {
            path: "/signup", //signup
            element: <div></div>, //<SignUpComponent/>
        },
    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: "/marketplace",
                    element: <div><MarketPlace/></div>,
                },
                {
                    path: "/items/:itemId",
                    element: <div><ItemDetailComponent/></div>,
                },
                {
                    path: "/profile",
                    element: <div><ProfilePage/></div>,

                },
                {
                    path: "/rens-co2",
                    element: <div><RENSCO2Page/></div>,
                },
                {
                    path: "/offers",
                    element: <div><OffersPage/></div>,
                },
                {
                    path: "/mylistings",
                    element: <div><MyListingsPage/></div>,
                },
                {
                    path: "/create-listing",
                    element: <div><CreateListItemFormComponent/></div>,
                },
                {
                    path: "/dashboard",
                    element: <div><RensDashboard/></div>,
                },
                {
                    path: "/reports",
                    element: <div><RENSCO2Page screen="raporlar"/></div>,
                }
            ],
        },
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: "/",
            element: <div><LoginComponent/></div>,
        },
        {
            path: "/login",
            element: <div><LoginComponent/></div>,
        },
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...routesForPublic,
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;