import React from 'react';

const DeleteConfirmationModal = ({ onConfirm, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Are you sure you want to delete this item?</h2>
        <div className="flex justify-end">
          <button onClick={onConfirm} className="bg-red-500 text-white py-2 px-4 rounded mr-2">Yes</button>
          <button onClick={onClose} className="bg-gray-500 text-white py-2 px-4 rounded">No</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;