import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import NatureIcon from '@mui/icons-material/Nature';
import Co2Icon from '@mui/icons-material/Co2';

const Sidebar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  const linkStyle = (path) => ({
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    borderRadius: '0.375rem',
    transition: 'background-color 0.2s',
    backgroundColor: activeLink === path ? '#0f4a46' : 'transparent',
  });

  const iconStyle = {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.75rem',
    color: '#FFFFFF',
  };

  return (
    <div className="sidebar flex flex-col fixed top-0 left-0 w-64 h-screen p-5 overflow-y-auto" style={{ backgroundColor: '#072927' }}>
      <div className="sidebar-header flex items-center mb-10">
        <Link to="/marketplace">
          <img 
            src={`${process.env.PUBLIC_URL}/LOGO_072927.png`} 
            alt="RENS Logo" 
            className="h-30 w-30" 
            style={{ height: '90px', width: '160px', marginRight: '50px', marginTop: '0px', marginLeft: '10px', cursor: 'pointer' }} 
          />
        </Link>
      </div>
      <nav className="flex-grow">
        <ul>
          <li className="mb-4">
            <Link 
              to="/dashboard" 
              style={linkStyle('/dashboard')} 
              className="hover:bg-[#0f4a46]" 
              onClick={() => handleLinkClick('/dashboard')}
            >
              <DashboardIcon style={iconStyle} />Dashboard
            </Link>
          </li>
          <li className="mb-4">
            <Link 
              to="/marketplace" 
              style={linkStyle('/marketplace')} 
              className="hover:bg-[#0f4a46]" 
              onClick={() => handleLinkClick('/marketplace')}
            >
              <StoreIcon style={iconStyle} />RENS Market
            </Link>
          </li>
          <li className="mb-4">
            <Link 
              to="/mylistings" 
              style={linkStyle('/mylistings')} 
              className="hover:bg-[#0f4a46]" 
              onClick={() => handleLinkClick('/mylistings')}
            >
              <ListAltIcon style={iconStyle} />İlanlarım
            </Link>
          </li>
          <li className="mb-4">
            <Link 
              to="/rens-co2" 
              style={linkStyle('/rens-co2')} 
              className="hover:bg-[#0f4a46]" 
              onClick={() => handleLinkClick('/rens-co2')}
            >
              <img 
                src={`${process.env.PUBLIC_URL}/images/co2_icon.png`} 
                alt="CO2 Icon" 
                style={{ color: '#FFFFFF', width: '24px', height: '20px', marginRight: '0.75rem' }} 
              />
              Rens.CO2
            </Link>
          </li>
          <li className="mb-4">
            <Link 
              to="/reports" 
              style={linkStyle('/reports')} 
              className="hover:bg-[#0f4a46]" 
              onClick={() => handleLinkClick('/reports')}
            >
              <BarChartIcon style={iconStyle} />Raporlar
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
