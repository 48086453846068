import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox
} from '@mui/material';
import { MonetizationOn as MonetizationOnIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import AuthService from "../services/AuthService";
import axios from "axios";
import {toast} from "react-toastify";
import {useAuth} from "../contexts/AuthProvider";

const useStyles = styled((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#018BC2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#016998',
    },
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  selectField: {
    margin: theme.spacing(1, 0),
  },
  fileInput: {
    margin: theme.spacing(1, 0),
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  checkbox: {
    margin: theme.spacing(1, 0),
  },
  dialogTitle: {
    backgroundColor: '#30723B',
    color: '#fff',
  },
  dialogContent: {
    backgroundColor: '#f9f9f9',
  },
  dialogActions: {
    backgroundColor: '#f9f9f9',
  },
}));

const OfferButton =  ({listItemId}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    sirketAdi: '',
    yetkiliAdi: '',
    yetkiliSoyadi: '',
    email: '',
    talepEdilenMiktar: '',
    birKismiMiktar: '',
    teklifVermek: '',
    lojistikSureci: '',
    fiyatTeklifiniz: '',
    lisansBelgesi: null,
    aciklama: '',
    sozlesmeOnay: false,
  });

  const {token} = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormValues({ ...formValues, lisansBelgesi: e.target.files[0] });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const offerMessage = JSON.stringify(formValues);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/offer`, {listItemId, offerMessage}, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      console.log(response.data);
      toast.success('Offer created successfully!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error creating listing:', error);
    }

    // Teklif gönderme işlemi burada yapılacak
    setOpen(false);
    setSnackbarOpen(true);
  };

  return (
    <div className="mt-4"> 
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#1f885cc4',
          height: '32px',
          padding: '4px 8px',
          borderRadius: '16px',
          fontSize: '0.75rem',
          ml: 'auto',
          '&:hover': {
            backgroundColor: '#47b285c4',
            boxShadow: 'none',
          },
        }}
        startIcon={<MonetizationOnIcon />}
        onClick={handleClickOpen}
      >
        Teklif Ver
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitle}>Teklif Ver</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography variant="body1" gutterBottom>
              Lütfen teklifinizi girin:
            </Typography>
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Şirket Adı"
              name="sirketAdi"
              value={formValues.sirketAdi}
              onChange={handleChange}
              required
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Yetkili Kişi Adı"
              name="yetkiliAdi"
              value={formValues.yetkiliAdi}
              onChange={handleChange}
              required
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Yetkili Kişi Soyadı"
              name="yetkiliSoyadi"
              value={formValues.yetkiliSoyadi}
              onChange={handleChange}
              required
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="E-mail"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              required
              type="email"
            />
            <FormControl component="fieldset" className={classes.radioGroup}>
              <InputLabel>Talep Edilen Miktar</InputLabel>
              <RadioGroup
                aria-label="talepEdilenMiktar"
                name="talepEdilenMiktar"
                value={formValues.talepEdilenMiktar}
                onChange={handleChange}
              >
                <FormControlLabel value="Tümü" control={<Radio />} label="Tümü" />
                <FormControlLabel value="Bir kısmı" control={<Radio />} label="Bir kısmı" />
              </RadioGroup>
            </FormControl>
            {formValues.talepEdilenMiktar === 'Bir kısmı' && (
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                fullWidth
                label='"Bir kısmı" ise miktar giriniz'
                name="birKismiMiktar"
                value={formValues.birKismiMiktar}
                onChange={handleChange}
              />
            )}
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Teklif vermek istediğiniz atığın adını teyit edin."
              name="teklifVermek"
              value={formValues.teklifVermek}
              onChange={handleChange}
              required
            />
            <FormControl fullWidth variant="outlined" className={classes.selectField}>
              <InputLabel>Lojistik Süreci</InputLabel>
              <Select
                name="lojistikSureci"
                value={formValues.lojistikSureci}
                onChange={handleChange}
                label="Lojistik Süreci"
                required
              >
                <MenuItem value="Lojistiği üstleniyorum.">Lojistiği üstleniyorum.</MenuItem>
                <MenuItem value="Lojistik satıcıya ait.">Lojistik satıcıya ait.</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Fiyat Teklifiniz"
              name="fiyatTeklifiniz"
              value={formValues.fiyatTeklifiniz}
              onChange={handleChange}
              required
              type="number"
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Açıklama"
              name="aciklama"
              value={formValues.aciklama}
              onChange={handleChange}
              multiline
              rows={3}
            />
            <input
              accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.rtf,.html,.zip,.mp3,.wma,.mpg,.flv,.avi,.jpg,.jpeg,.png,.gif"
              className={classes.fileInput}
              style={{ display: 'none' }}
              id="file-input"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file-input">
              <Button variant="contained" color="primary" component="span" fullWidth>
                Lisans belgenizi yükleyiniz. (Alım yetkisi)
              </Button>
            </label>
            <FormControlLabel
              control={
                <Checkbox
                  name="sozlesmeOnay"
                  checked={formValues.sozlesmeOnay}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Sözleşmeyi onaylıyorum"
              className={classes.checkbox}
            />
            <DialogActions className={classes.dialogActions}>
              <Button onClick={handleClose} color="secondary" className={classes.submit}>
                İptal
              </Button>
              <Button type="submit" color="primary" className={classes.submit}>
                Gönder
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message='Teklifiniz gönderildi'
      />
    </div>
  );
};
export default OfferButton;