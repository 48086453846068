import React from 'react';
import { category_map } from '../assets/categories';
import { FormControl, MenuItem, Select, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
//import './SearchComponent.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const SearchComponent = ({ filter, setFilter , searchClicked, setSearchClicked }) => {
    const parseCategoryKey = (key) => parseInt(key, 10);
    const sortCategoryKeys = (keys) => {
        return keys
            .map(parseCategoryKey)
            .filter((num) => !isNaN(num))
            .sort((a, b) => a - b)
            .map((num) => num.toString().padStart(2, '0'));
    };

    const mainCategories = sortCategoryKeys(Object.keys(category_map)
        .filter(key => key.length === 2)).reduce((obj, key) => {
            obj[key] = category_map[key];
            return obj;
        }, {});

    const subCategories = Object.keys(category_map)
        .filter(key => key.startsWith(filter.category1) && key.length === 4)
        .reduce((obj, key) => {
            obj[key] = category_map[key];
            return obj;
        }, {});

    const subSubCategories = Object.keys(category_map)
        .filter(key => key.startsWith(filter.category2) && key.length > 4)
        .reduce((obj, key) => {
            obj[key] = category_map[key];
            return obj;
        }, {});

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    const handleClear = () => {
        setFilter({
            category1: '',
            category2: '',
            category3: '',
            code: '',
            title: '',
            page: 1,
            limit: 10,
        });
    };

    const clickAction = () => {
        setSearchClicked(!searchClicked);
    };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center', 
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: '12px', flex: 1, alignItems: 'center' }}>
                    <input
                    placeholder="Başlık"
                    name="title"
                    value={filter.title}
                    onChange={handleFilterChange}
                    className="w-full bg-[#0F4A46] text-white placeholder-white rounded-lg p-2"
                    style={{ fontSize: '0.8rem' }}
                    />
                    <input
                    placeholder="Kod"
                    name="code"
                    value={filter.code}
                    onChange={handleFilterChange}
                    className="w-full bg-[#0F4A46] text-white placeholder-white rounded-lg p-2"
                    style={{ fontSize: '0.8rem' }}
                    />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
                    <select
                    name="category1"
                    placeholder="Kategori 1"
                    value={filter.category1}
                    onChange={handleFilterChange}
                    className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
                    style={{ fontSize: '0.8rem' }}
                    >
                    <option value=""><em>Kategori 1</em></option>
                    {Object.entries(mainCategories)
                        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
                        .map(([key, value]) => (
                        <option key={key} value={key} className="text-black rounded-lg p-2">{value}</option>
                        ))}
                    </select>
                    <select
                    name="category2"
                    value={filter.category2}
                    onChange={handleFilterChange}
                    className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
                    style={{ fontSize: '0.8rem' }}
                    >
                    <option value=""><em>Kategori 2</em></option>
                    {Object.entries(subCategories)
                        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
                        .map(([key, value]) => (
                        <option key={key} value={key} className="text-black rounded-lg p-2">{value}</option>
                        ))}
                    </select>
                    <select
                    name="category3"
                    value={filter.category3}
                    onChange={handleFilterChange}
                    className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
                    style={{ fontSize: '0.8rem' }}
                    >
                    <option value=""><em>Kategori 3</em></option>
                    {Object.entries(subSubCategories)
                        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
                        .map(([key, value]) => (
                        <option key={key} value={key} className="text-black rounded-lg p-2">{value}</option>
                        ))}
                    </select>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconButton
                onClick={clickAction}
                sx={{ borderRadius: '16px', width: '40px', height: '40px', color: '#FFFFFF' }}
                >
                <SearchIcon sx={{ fontSize: '20px' }} />
                </IconButton>
                <IconButton
                onClick={handleClear}
                sx={{ borderRadius: '16px', width: '40px', height: '40px', color: '#FFFFFF' }}
                >
                <ClearIcon sx={{ fontSize: '20px' }} />
                </IconButton>
            </Box>
        </Box>
    );
}

export default SearchComponent;
