import React, { useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import styles from '../pages/RensDashboard.module.css';

const MetricCard = ({ title, value, icon: Icon, secondaryContent }) => {
  const [activeView, setActiveView] = useState(1);

  return (
    <Card className={styles.metricCard}>
      <CardContent>
        <div className={styles.iconWrapper}>
          <Icon size={18} />
        </div>
        <AnimatePresence mode="wait">
          {activeView === 1 ? (
            <motion.div
              key="primary"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Typography variant="body2" className={styles.metricTitle}>
                {title}
              </Typography>
              <Typography variant="h6" className={styles.metricValue}>
                {value}
              </Typography>
            </motion.div>
          ) : (
            <motion.div
              key="secondary"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Typography variant="body2" className={styles.metricTitle}>
                {secondaryContent?.title || title}
              </Typography>
              <Typography variant="h6" className={styles.metricValue}>
                {secondaryContent?.value || value}
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>

        <div className={styles.indicatorContainer}>
          <button
            onClick={() => setActiveView(1)}
            className={`${styles.indicator} ${activeView === 1 ? styles.active : ''}`}
            aria-label="Show primary content"
          />
          <button
            onClick={() => setActiveView(2)}
            className={`${styles.indicator} ${activeView === 2 ? styles.active : ''}`}
            aria-label="Show secondary content"
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default MetricCard;