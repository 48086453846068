import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useAuth } from "../contexts/AuthProvider";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { FormControl } from '@mui/material';
import { FaSearch, FaEdit, FaTrash, FaGasPump, FaCar, FaBolt, FaRecycle } from 'react-icons/fa';


export default function EditEmissionDataDialog({ item, items, setItems }) {
    const [open, setOpen] = React.useState(false);
    const { token } = useAuth();
    const [successMessage, setSuccessMessage] = React.useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        item[name] = value;
        setItems(items.map(realItem => realItem.id === item.id ? { ...realItem, name: value } : realItem))
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSuccessMessage("");
    };

    const getEmissionDate = (emissionDate) => {
        const unformattedDate = new Date(emissionDate);
        if (emissionDate == null) {
            return "";
        } 
        return unformattedDate.getFullYear().toString() +
            "-" +
            (unformattedDate.getMonth() + 1).toString().padStart(2, 0) +
            "-" +
            unformattedDate.getDate().toString().padStart(2, 0)
    }

    const handleEdit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/emission/${item.id}`, item, {
                headers: {
                    'x-access-token': token,
                },
            }).then(response => {
                let statusCode = response.status;

                if (statusCode = "200") {
                    setSuccessMessage("Emisyon Verisi Basariyla Guncellendi");
                }
            });
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    return (

        <React.Fragment>
            <button variant="outlined" onClick={handleClickOpen} className="mr-2 text-white hover:text-white/80 transition-colors duration-200">
                <FaEdit />
            </button>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event => {
                        handleEdit(event);
                    })
                }}
            >
                <DialogTitle>Emisyon Verisi Duzenle</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            name="emissionName"
                            label="Emisyon Adi"
                            type="text"
                            aria-label="Emisyon Adi"
                            placeholder="Emisyon Adi"
                            value={item.emissionName}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            name="amount"
                            label="Miktar"
                            type="number"
                            aria-label="Miktar"
                            placeholder="Type a number…"
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            value={item.amount}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Başlangıç Tarihi"
                            type="date"
                            name="usagePeriodStart"
                            value= {getEmissionDate(item.startDate)}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Bitiş Tarihi"
                            type="date"
                            name="usagePeriodEnd"
                            value={getEmissionDate(item.endDate)}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>IPTAL</Button>
                    <Button type="submit">KAYDET</Button>
                </DialogActions>
                {successMessage &&
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setSuccessMessage("");
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        severity="success">
                        <AlertTitle>Info</AlertTitle>
                        {successMessage}
                    </Alert>}
            </Dialog>

        </React.Fragment>

    );
}