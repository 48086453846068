import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../contexts/AuthProvider";

export const ProtectedRoute = () => {
    const { token } = useAuth();

    // Check if the user is authenticated
    if (!token) {
        console.log("tokennot found")
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
    }
    //console.log("token var")

    // If authenticated, render the child routes
    return <Outlet />;
};