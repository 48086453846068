import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaSearch, FaEye, FaDownload } from 'react-icons/fa';
import axios from 'axios';
import { useAuth } from "../contexts/AuthProvider";
import DeleteDialog from "./DeleteDialog.js";


const ReportsPage = () => {
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        scope: '',
    });

    const { token } = useAuth();
    const [reports, setReports] = useState([]);
    const bucketUrl = `${process.env.REACT_APP_BUCKET_URL}`;


    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const generateReport = async () => {

        console.log(filters);
        const requestParams = { startDate: filters.startDate, endDate: filters.endDate }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/calculator/pdf`, {
                headers: { 'x-access-token': token },
                params: requestParams,
            }).then(response => {
                console.log(response.status)
                if (response.status == "201" || response.status == "200") {
                    fetchReports();
                }
            });
            console.log(response);
        } catch (error) {
            console.error('Error generating pdf:', error);
        }
    };

    const fetchReports = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/report`, {
                headers: { 'x-access-token': token }
            });
            console.log(response);
            setReports(response.data);
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    };

    useEffect(() => {
            fetchReports();
    }, []);

    const getFormattedDate = (date) => {
            if (date == null) {
                return "";
            }
            const unformattedDate = new Date(date);
            return unformattedDate.getFullYear().toString() +
                "-" +
                (unformattedDate.getMonth() + 1).toString().padStart(2, 0) +
                "-" +
                unformattedDate.getDate().toString().padStart(2, 0)
    };

    return (
        <div className="bg-[#0F4A46] rounded-2xl p-6">
            <h2 className="text-2xl font-bold mb-6 text-white">Raporlar</h2>
            <div className="bg-[#165853] rounded-xl p-6 mb-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <div className="flex flex-col">
                        <label htmlFor="startDate" className="text-white mb-1">Başlangıç Tarihi</label>
                        <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            value={filters.startDate}
                            onChange={handleFilterChange}
                            className="bg-[#0F4A46] text-white rounded-lg p-2"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="endDate" className="text-white mb-1">Bitiş Tarihi</label>
                        <input
                            type="date"
                            id="endDate"
                            name="endDate"
                            value={filters.endDate}
                            onChange={handleFilterChange}
                            className="bg-[#0F4A46] text-white rounded-lg p-2"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="scope" className="text-white mb-1">Kapsam</label>
                        <select
                            id="scope"
                            name="scope"
                            value={filters.scope}
                            onChange={handleFilterChange}
                            className="bg-[#0F4A46] text-white rounded-lg p-2"
                        >
                            <option className="text-black" value="">Kapsam Seçin</option>
                            <option className="text-black" value="Kapsam 1">Kapsam 1</option>
                            <option className="text-black" value="Kapsam 2">Kapsam 2</option>
                            <option className="text-black" value="Kapsam 3">Kapsam 3</option>
                        </select>
                    </div>
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={generateReport}
                        className="bg-[#0F4A46] text-white rounded-lg px-6 py-2 flex items-center justify-center hover:bg-[#1a6b64] transition-colors duration-300"
                    >
                        <FaSearch className="mr-2" />
                        Rapor Oluştur
                    </button>
                </div>
            </div>
            
            <h3 className="text-xl font-bold mb-4 text-white">Oluşturulan Raporlar</h3>
            <div className="bg-[#165853] rounded-xl p-6 overflow-x-auto">
                <table className="w-full text-white">
                    <thead>
                        <tr className="border-b border-white/10">
                            <th className="text-left p-2">Rapor Adı</th>
                            <th className="text-left p-2">Tarih</th>
                            <th className="text-left p-2">Kapsam</th>
                            <th className="text-left p-2">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((report) => (
                            <motion.tr
                                key={report.id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="border-b border-white/10 hover:bg-[#1a6b64] transition-colors duration-200"
                            >
                                <td className="p-2">{report.source_url}</td>
                                <td className="p-2">{getFormattedDate(report.createdAt)}</td>
                                <td className="p-2">{"all"}</td>
                                <td className="p-2">
                                    <DeleteDialog item={report} setItems={setReports} url={"/report"} itemName={report.source_url}>
                                    </DeleteDialog>

                                    <a className="btn btn-info text-white hover:text-white/80 transition-colors duration-200"  href={`${process.env.REACT_APP_BUCKET_URL}`+ "/" + report.source_url}  >
                                         <button>
                                            <FaDownload />
                                         </button>
                                    </a>
                                </td>
                            </motion.tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReportsPage;