import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from "../contexts/AuthProvider";
import { motion } from 'framer-motion';
import { FaSearch, FaEdit, FaTrash, FaGasPump, FaCar, FaBolt, FaRecycle } from 'react-icons/fa';
import EditEmissionDataDialog from "./EditEmissionDataDialog.js";
import DeleteDialog from "./DeleteDialog.js";


const EmissionDataViewPage = () => {
    const [emissionRecords, setEmissionRecords] = useState([]);
    const [searchRequest, setSearchRequest] = useState({
        emissionName: '',
        scopeTableName: '',
        startDate: '',
        endDate: ''
    });
    const { token } = useAuth();

    const scopeTableNameMap = {
        "scope1_fuels": "Sabit Yanma Kaynaklı Emisyonlar",
        "scope1_bioenergy": "Biyoyakıtların Sabit Yanmasından Kaynaklanan Emisyonlar",
        "scope1_cars": "Şirket Araçlarından Kaynaklanan Emisyonlar",
        "scope1_others": "Soğutucu & Diğer Gaz Sızıntıları",
        "scope2": "Satın Alınan Elektrik Kaynaklı Emisyonlar",
        "scope3_materialuse": "Satın Alınan Mallara Ait Emisyonlar",
        "scope3_wastedisposal": "Atık Yönetiminden Kaynaklanan Emisyonlar",
        "scope3_water": "Su Kullanımı Kaynaklı Emisyonlar",
        "scope3_hotelstay": "Personel Otel Konaklamasına Ait Emisyonlar"
    };

    const handleSearchRequestChange = (e) => {
        const { name, value } = e.target;
        setSearchRequest({ ...searchRequest, [name]: value });
    };

    const getEmissionRecords = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/emission`, {
                headers: { 'x-access-token': token },
            });
            setEmissionRecords(response.data);
        } catch (error) {
            console.error('Error getEmissionRecords:', error);
        }
    };

    const handleSearch = async () => {
        try {
            const params = Object.fromEntries(Object.entries(searchRequest).filter(([_, value]) => value));
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/search/emission`, {
                headers: { 'x-access-token': token },
                params: params,
            });
            setEmissionRecords(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    useEffect(() => {
        getEmissionRecords();
    }, []);

    const getScopeName = (scopeTableName) => {
        if (scopeTableName.includes("scope1")) return "Kapsam 1";
        if (scopeTableName.includes("scope2")) return "Kapsam 2";
        if (scopeTableName.includes("scope3")) return "Kapsam 3";
    };

    const getScopeIcon = (scopeTableName) => {
        if (scopeTableName.includes("scope1")) {
            if (scopeTableName.includes("cars")) {
                return <FaCar className="text-yellow-400" />;
            }
            return <FaGasPump className="text-yellow-400" />;
        }
        if (scopeTableName.includes("scope2")) return <FaBolt className="text-blue-400" />;
        if (scopeTableName.includes("scope3")) return <FaRecycle className="text-green-400" />;
    };

    const getEmissionDate = (emissionDate) => {
        if (!emissionDate) return "";
        const date = new Date(emissionDate);
        return date.toISOString().split('T')[0];
    };

    return (
        <div className="bg-[#0F4A46] rounded-2xl p-6">
            <h2 className="text-2xl font-bold mb-6 text-white">Emisyon Verileri</h2>
            <div className="bg-[#165853] rounded-xl p-6 mb-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                    <input
                        type="text"
                        name="emissionName"
                        placeholder="Emisyon Adı"
                        value={searchRequest.emissionName}
                        onChange={handleSearchRequestChange}
                        className="bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
                    />
                    <select
                        name="scopeTableName"
                        value={searchRequest.scopeTableName}
                        onChange={handleSearchRequestChange}
                        className="bg-[#0F4A46] text-white rounded-lg p-2"
                    >
                        <option className="text-black" value="">Kapsam Seçin</option>
                        <option className="text-black" value="scope1">Kapsam 1</option>
                        <option className="text-black" value="scope2">Kapsam 2</option>
                        <option className="text-black" value="scope3">Kapsam 3</option>
                    </select>
                    <input
                        type="date"
                        name="startDate"
                        value={searchRequest.startDate}
                        onChange={handleSearchRequestChange}
                        className="bg-[#0F4A46] text-white rounded-lg p-2"
                    />
                    <input
                        type="date"
                        name="endDate"
                        value={searchRequest.endDate}
                        onChange={handleSearchRequestChange}
                        className="bg-[#0F4A46] text-white rounded-lg p-2"
                    />
                    <button
                        onClick={handleSearch}
                        className="bg-[#0F4A46] text-white rounded-lg p-2 flex items-center justify-center hover:bg-[#1a6b64] transition-colors duration-300"
                    >
                        <FaSearch className="mr-2" />
                        Ara
                    </button>
                </div>
            </div>
            <div className="bg-[#165853] rounded-xl p-6 overflow-x-auto">
                <table className="w-full text-white">
                    <thead>
                        <tr className="border-b border-white/10">
                            <th className="text-left p-2">Kapsam</th>
                            <th className="text-left p-2">Veri Adı</th>
                            <th className="text-left p-2">Alt Başlık</th>
                            <th className="text-left p-2">Tarih Aralığı</th>
                            <th className="text-left p-2">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emissionRecords.map((item) => (
                            <motion.tr
                                key={item.id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="border-b border-white/10 hover:bg-[#1a6b64] transition-colors duration-200"
                            >
                                <td className="p-2 flex items-center">
                                    {getScopeIcon(item.scopeTableName)}
                                    <span className="ml-2">{getScopeName(item.scopeTableName)}</span>
                                </td>
                                <td className="p-2">{item.emissionName || ""}</td>
                                <td className="p-2">{scopeTableNameMap[item.scopeTableName]}</td>
                                <td className="p-2">{getEmissionDate(item.startDate)} / {getEmissionDate(item.endDate)}</td>
                                <td className="p-2">
                                 <EditEmissionDataDialog  className="mr-2 text-white hover:text-white/80 transition-colors duration-200" item={item} items={emissionRecords} setItems={setEmissionRecords}>
                                 </EditEmissionDataDialog>

                                 <DeleteDialog item={item} setItems={setEmissionRecords} url={"/emission"} itemName= {item.emissionName}>
                                 </DeleteDialog>
                                </td>
                            </motion.tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EmissionDataViewPage;