import React from 'react';
import { Link } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import { User } from 'lucide-react';

const Header = ({ userName }) => {
  return (
    <>
      <style jsx>{`
        .header-custom-bg {
          background-color: #072927 !important;
        }
      `}</style>
      <div className="header header-custom-bg p-4 shadow-md">
        <div className="flex items-center">
          <Link to="/profile" className="ml-4 text-white flex items-center">
            <User className="text-white h-6 w-6" />
            {userName ? userName : ''}
          </Link>
          <Link 
            to="/login" 
            className="ml-4 text-white flex items-center" 
            onClick={() => {
                localStorage.removeItem('token');
                localStorage.clear();
            }}
          >
            <Logout className="text-white h-6 w-6" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
