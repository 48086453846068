import React, { useState } from "react";
import { Map, APIProvider, AdvancedMarker } from "@vis.gl/react-google-maps";


const CustomMap = ({ markerLocation, setMarkerLocation }) => {

  const markerChanged = (ev) => {
    setMarkerLocation(ev.detail.latLng);
  }


  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries = {["maps","places"]}>
    <Map
      mapId={"rensmap"}
      defaultZoom={13}
      defaultCenter={markerLocation}
      center={markerLocation}
      onClick={markerChanged}>
      <AdvancedMarker position={markerLocation}></AdvancedMarker>
    </Map>
    </APIProvider>

  );
}

export default CustomMap;