import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { category_map } from '../assets/categories';
import { useAuth } from "../contexts/AuthProvider";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Calendar, Package, Truck, Info, MapPin, Tag } from 'lucide-react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Typography from '@mui/material/Typography';
import OfferButton from './OfferButton';
import styles from '../pages/MarketPlace.module.css';
import CustomMap from './CustomMap';

const ItemDetailComponent = () => {
    const [item, setItem] = useState(null);
    const { itemId } = useParams();
    const location = useLocation();
    const isMyItem = location.state?.isMyItem;
    const { token } = useAuth();
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
    const [images, setImages] = useState([
        { original: "", thumbnail: 'https://via.placeholder.com/150x100?text=Image+1' },
        { original: 'https://via.placeholder.com/600x400?text=Image+2', thumbnail: 'https://via.placeholder.com/150x100?text=Image+2' },
        { original: 'https://via.placeholder.com/600x400?text=Image+3', thumbnail: 'https://via.placeholder.com/150x100?text=Image+3' },
    ]);
    const [markerLocation, setMarkerLocation] = useState({
        lat: 51.509865,
        lng: -0.118092,
    });

    useEffect(() => {
        const fetchItemDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listItem/${itemId}`, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setItem(response.data);
                let photoUrlState = [];
                for (let i = 0; i < images.length; i++) {
                    if (response.data.ItemPhotoHistories) {
                        if (response.data.ItemPhotoHistories[i]) {
                            photoUrlState.push({ original: response.data.ItemPhotoHistories[i].source_url, thumbnail: response.data.ItemPhotoHistories[i].source_url });
                        }
                    }
                }

                setImages(photoUrlState);
                setMarkerLocation({lat: Number(response.data.latitude), lng: Number(response.data.longitude)});

            } catch (error) {
                console.error('Error fetching item details:', error);
            }
        };

        fetchItemDetails();
    }, [itemId, token]);

    if (!item) return (
        <div
            className="flex justify-center items-center h-screen"
            style={{ backgroundColor: 'rgba(211, 211, 211, 0.4)' }}
        >
            <div
                className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2"
                style={{ borderColor: '#0F4A46' }}
            ></div>
        </div>
    );


    const tags = [
        category_map[item.category1],
        category_map[item.category2],
        category_map[item.category3],
        item.condition
    ].filter(Boolean);

    return (
        <div className="flex bg-gray-100 min-h-screen">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden ml-64">
                <Header />
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
                    <div className="itemDetail-container mx-auto px-6 py-8">
                        <div className={styles.stepHeader}>
                            <img src="/rensmarketlogo.png" alt="Rens Market Logo" className={styles.stepLogo} />
                            <h2 className="text-xl font-semibold text-black">İlan Detayları</h2>
                        </div>
                        <div className="bg-[#0F4A46] shadow-lg rounded-2xl overflow-hidden">
                            <div className="md:flex" style={{ maxWidth: 'fit-content' }}>
                                <div className="md:flex-shrink-0" style={{ width: '400px' }}>
                                    <div className="image-gallery-wrapper">
                                        <ImageGallery 
                                            items={images} 
                                            showThumbnails={true} 
                                            showPlayButton={false} 
                                        />
                                    </div>
                                </div>
                                <div className="p-8 flex-grow">
                                    <div className="uppercase tracking-wide text-sm text-white/60 font-semibold">{category_map[item.category3]}</div>
                                    <h2 className="block mt-1 text-2xl leading-tight font-bold text-white">{item.title}</h2>
                                    <div className="mt-4 text-2xl font-bold text-white/70">₺ {item.price} / {item.unit}</div>
                                    <div className="mt-4 flex flex-wrap gap-2">
                                        {tags.map((tag, index) => (
                                            <span key={index} className="px-2 py-1 bg-[#165853] text-white/70 text-xs font-medium rounded-full">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                    {isMyItem && (
                                        <OfferButton listItemId ={itemId}></OfferButton>
                                    )}
                                </div>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-white flex items-center">
                                            <Package className="h-5 w-5 mr-2" />
                                            Miktar
                                        </dt>
                                        <dd className="mt-1 text-sm text-white/70 sm:mt-0 sm:col-span-2">{item.amount} {item.unit}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-white flex items-center">
                                            <Info className="h-5 w-5 mr-2" />
                                            Açıklama
                                        </dt>
                                        <dd className="mt-1 text-sm text-white/70 sm:mt-0 sm:col-span-2">{item.description}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-white flex items-center">
                                            <Calendar className="h-5 w-5 mr-2" />
                                            Talep Edilen Alım Tarihi
                                        </dt>
                                        <dd className="mt-1 text-sm text-white/70 sm:mt-0 sm:col-span-2">
                                            {new Date(item.date_of_demanded_pickup).toLocaleDateString()}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-white flex items-center">
                                            <Truck className="h-5 w-5 mr-2" />
                                            Lojistik
                                        </dt>
                                        <dd className="mt-1 text-sm text-white/70 sm:mt-0 sm:col-span-2">{item.who_handle_logistics}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-white flex items-center">
                                            <MapPin className="h-5 w-5 mr-2" />
                                            Konum
                                        </dt>
                                        <dd className="mt-1 text-sm text-white/70 sm:mt-0 sm:col-span-2">
                                            <div style={{ height: '300px', width: '100%' }}>
                                                <CustomMap markerLocation={markerLocation} setMarkerLocation={setMarkerLocation}>
                                                </CustomMap>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        
                        <div className="mt-8 bg-[#0F4A46] shadow-lg rounded-2xl overflow-hidden">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-white">Satıcı Bilgileri</h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
                                <dl className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-white">İsim</dt>
                                        <dd className="mt-1 text-sm text-white/70">{item.seller_name}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-white">Telefon</dt>
                                        <dd className="mt-1 text-sm text-white/70">{item.seller_phone_number}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-white">E-posta</dt>
                                        <dd className="mt-1 text-sm text-white/70">{item.seller_email}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-white">İşletme Adı</dt>
                                        <dd className="mt-1 text-sm text-white/70">{item.business_name}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ItemDetailComponent;
