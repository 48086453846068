import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { Recycle, BarChart2, Zap, Flame } from 'lucide-react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import MetricCard from '../components/metric-card';
import styles from './RensDashboard.module.css';
import axios from 'axios';
import { useAuth } from "../contexts/AuthProvider";
import { ActivityIndicator, View } from 'react-native';

const mockMarketData = {
  managedWasteEmissions: 0,
  wasteListedTotalAmount: [{ unit: "", amount: 0 }],
  recycledWaste: [{ unit: "", amount: 0 }],
  listings: 0,
  wasteDistribution: [
    { name: 'Booked', value: 36.2 },
    { name: 'Flopped', value: 63.8 },
  ],
  monthlyListings: [
    { month: 'Jan', value: 0 },
    { month: 'Feb', value: 0 },
    { month: 'Mar', value: 0 },
    { month: 'Apr', value: 0 },
    { month: 'May', value: 0 },
    { month: 'Jun', value: 0 },
    { month: 'Jul', value: 0 },
    { month: 'Aug', value: 0 },
    { month: 'Sep', value: 0 },
    { month: 'Oct', value: 0 },
    { month: 'Nov', value: 0 },
    { month: 'Dec', value: 0 },
  ],
};

const mockCO2Data = {
  totalEmissions: 0,
  scope1: 0,
  scope2: 0,
  scope3: 0,
  monthlyEmissions: [
    { month: 'Jan', scope1: 2000, scope2: 3000, scope3: 2000 },
    { month: 'Feb', scope1: 8000, scope2: 10000, scope3: 5000 },
    { month: 'Mar', scope1: 3000, scope2: 7000, scope3: 2000 },
    { month: 'Apr', scope1: 9000, scope2: 8000, scope3: 8000 },
    { month: 'May', scope1: 12000, scope2: 15000, scope3: 10000 },
    { month: 'Jun', scope1: 8000, scope2: 13000, scope3: 12000 },
    { month: 'Jul', scope1: 2000, scope2: 3000, scope3: 2000 },
    { month: 'Aug', scope1: 8000, scope2: 10000, scope3: 5000 },
    { month: 'Sep', scope1: 3000, scope2: 7000, scope3: 2000 },
    { month: 'Oct', scope1: 9000, scope2: 8000, scope3: 8000 },
    { month: 'Nov', scope1: 12000, scope2: 15000, scope3: 10000 },
    { month: 'Dec', scope1: 8000, scope2: 13000, scope3: 12000 },
  ],
  perEmployeeEmissions: 0,
  emissionDistribution: [
    { name: 'Q1', value: 13.1 },
    { name: 'Q2', value: 28.6 },
    { name: 'Q3', value: 28.0 },
    { name: 'Q4', value: 30.3 },
  ],
};

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300'];

const ChartCard = ({ title, children }) => (
  <Card className={styles.chartContainer}>
    <CardContent>
      <Typography variant="body2" className={styles.chartTitle}>
        {title}
      </Typography>
      {children}
    </CardContent>
  </Card>
);

const SingleMetricCard = ({ title, value, icon: Icon }) => (
  <Card className={styles.metricCard}>
    <CardContent>
      <div className={styles.iconWrapper}>
        <Icon size={18} />
      </div>
      <Typography variant="body2" className={styles.metricTitle}>
        {title}
      </Typography>
      <Typography variant="h6" className={styles.metricValue}>
        {value}
      </Typography>
    </CardContent>
  </Card>
);

export default function RensDashboard() {
  const [showPlatformOverview, setShowPlatformOverview] = useState(false);
  const [marketData, setMarketData] = useState(mockMarketData);
  const [co2Data, setCO2Data] = useState(mockCO2Data);
  const [myListItems, setMyListItems] = useState([]);
  const [numberOfEmployee, setNumberOfEmployee] = useState(1);
  const { token } = useAuth();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [openProgress, setOpenProgress] = useState(false);
  const [userName, setUserName] = useState('');


  const fetchMyListings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
        headers: {
          'x-access-token': token,
        },
      }).then(res => {
        let statusCode = res.status;

        if (statusCode === 200) {
          setUserName(res.data.user.name);
          setNumberOfEmployee(res.data.user.numberOfEmployee);
          setMarketData((prevData) => ({
            ...prevData,
            listings: res.data.listItems.length,
          }));

          var listItemsUnitMap = Map.groupBy(res.data.listItems, (item) => item.unit);
          setMyListItems(res.data.listItems);

          var resultUnitAmountList = [];
          for (let [key, value] of listItemsUnitMap) {
            var totalAmount = value.reduce((accumulator, current) => accumulator + parseFloat(current.amount, 10), 0);
            resultUnitAmountList.push({ unit: key, amount: totalAmount });
          }
          setMarketData((prevData) => ({
            ...prevData,
            wasteListedTotalAmount: resultUnitAmountList
          }));
          var listItemsMonthMap = Map.groupBy(res.data.listItems, (item) => monthNames[new Date(item.createdAt).getMonth()]);
          var resultUnitAmountMonthList = [];
          for (let monthIndex in monthNames) {
            if (!listItemsMonthMap.has(monthNames[monthIndex])) {
              resultUnitAmountMonthList.push({ month: monthNames[monthIndex], value: 0 });
            } else {
              resultUnitAmountMonthList.push({ month: monthNames[monthIndex], value: listItemsMonthMap.get(monthNames[monthIndex]).length });
            }
          }
          setMarketData((prevData) => ({
            ...prevData,
            monthlyListings: resultUnitAmountMonthList
          })); 
        }
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  function groupByUnit({ listItem }) {
    return listItem.unit;
  }

  const getOffers = async () => {
    try {
      const offer = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/offer/myItems`, {
        headers: {
          'x-access-token': token,
        },
      }).then(res => {
        let statusCode = res.status;
        if (statusCode = "200") {
          var resultMap = Map.groupBy(res.data, groupByUnit);
          var resultUnitAmountList = [];
          for (let [key, value] of resultMap) {
            var totalAmount = value.filter(offer => offer.approved === "true").reduce((accumulator, current) => accumulator + parseFloat(current.listItem.amount, 10), 0);
            resultUnitAmountList.push({ unit: key, amount: totalAmount });
          }
          setMarketData((prevData) => ({
            ...prevData,
            recycledWaste: resultUnitAmountList
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const getCalculatorEmissionResult = async (filters) => {

    const requestParams = { startDate: filters.usagePeriodStart, endDate: filters.usagePeriodEnd }
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/calculator/emission`, {
        headers: { 'x-access-token': token },
        params: requestParams,
      }).then(response => {
        if (response.status == "201" || response.status == "200") {
          if (response.data && response.data.data && response.data.data.scope3 && response.data.data.scope3.length > 0) {
            var sumOfWasteDisposal = response.data.data.scope3.filter(scope3Item => scope3Item.scopeTableName.includes("wastedisposal"))
              .reduce((accumulator, current) => accumulator + parseInt(current.calculatedValue, 10), 0);
            setMarketData((prevData) => ({
              ...prevData,
              managedWasteEmissions: parseFloat(sumOfWasteDisposal / 1000, 10).toFixed(2)
            }));
          }
          var totalCalculatorEmission = parseFloat((response.data.data.total1 + response.data.data.total2 + response.data.data.total3) / 1000 , 10).toFixed(2);
          setCO2Data((prevData) => ({
            ...prevData,
            totalEmissions: totalCalculatorEmission,
            scope1: parseFloat(response.data.data.total1 / 1000, 10).toFixed(2) ,
            scope2: parseFloat(response.data.data.total2 / 1000, 10).toFixed(2),
            scope3: parseFloat(response.data.data.total3 / 1000, 10).toFixed(2),
            perEmployeeEmissions: (totalCalculatorEmission / numberOfEmployee ).toFixed(2)
          }));
          setOpenProgress(false);
        }
      });
    } catch (error) {
      console.error('Error calculating emission data:', error);
    }
  };

  useEffect(() => {
    setOpenProgress(true);
    fetchMyListings();
    getOffers();
    getCalculatorEmissionResult({ usagePeriodStart: null, usagePeriodEnd: null });
  }, [token]);


  const UserInformationDashboard = () => (
    <div className={styles.dashboardContent}>
      <div className={styles.stepContainer}>
        <div className={styles.stepHeader}>
          <img src="/rensmarketlogo.png" alt="Rens Market Logo" className={styles.stepLogo} />
        </div>
        <div className={styles.metricsGroup}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <SingleMetricCard
                title="Atık Yönetimi Kaynaklı Emisyon"
                value={`${marketData.managedWasteEmissions} tCO2e`}
                icon={() => (
                  <img 
                    src={`${process.env.PUBLIC_URL}/images/co2_icon_green.png`} 
                    alt="CO2 Icon" 
                    style={{ width: '26px', height: '19px' }} 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {marketData.wasteListedTotalAmount.length > 1 ?
                <MetricCard
                  title="İlandaki Atık Miktarı"
                  value={`${marketData.wasteListedTotalAmount[0].amount} ${marketData.wasteListedTotalAmount[0].unit}`}
                  icon={BarChart2}
                  secondaryContent={{
                    title: "İlandaki Atık Miktarı",
                    value: `${marketData.wasteListedTotalAmount[1].amount} ${marketData.wasteListedTotalAmount[1].unit}`
                  }}
                /> :
                <SingleMetricCard
                  title="İlandaki Atık Miktarı"
                  value={marketData.wasteListedTotalAmount[0] ? `${marketData.wasteListedTotalAmount[0].amount} ${marketData.wasteListedTotalAmount[0].unit}` : 0}
                  icon={BarChart2}>
                </SingleMetricCard>
              }
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {marketData.recycledWaste.length > 1 ?
                <MetricCard
                  title="Geri Dönüştürülen Atık Miktarı"
                  value={`${marketData.recycledWaste[0].amount} ${marketData.recycledWaste[0].unit}`}
                  icon={Recycle}
                  secondaryContent={{
                    title: "Geri Dönüştürülen Atık Miktarı",
                    value: `${marketData.recycledWaste[1].amount} ${marketData.recycledWaste[1].unit}`
                  }}
                />
                :
                <SingleMetricCard
                  title="Geri Dönüştürülen Atık Miktarı"
                  value= { marketData.recycledWaste[0] ? `${marketData.recycledWaste[0].amount} ${marketData.recycledWaste[0].unit}` : 0}
                  icon={Recycle}
                />
              }

            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SingleMetricCard
                title="İlanlarınız"
                value={marketData.listings}
                icon={ListAltIcon}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ChartCard title="Kategori Dağılımı">
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie
                    data={marketData.wasteDistribution}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={60}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {marketData.wasteDistribution.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartCard title="Yıllık İlan Dağılımı">
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={marketData.monthlyListings}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>

        </Grid>
      </div>

      <div className={styles.stepContainer}>
        <div className={styles.stepHeader}>
          <img src="/rens.co2logo.png" alt="Rens CO2 Logo" className={styles.stepLogo} />
        </div>
        <div className={styles.metricsGroup}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <SingleMetricCard
                title="Toplam Emisyon Miktarı"
                value={`${co2Data.totalEmissions} tCO2e`}
                icon={() => (
                  <img 
                    src={`${process.env.PUBLIC_URL}/images/co2_icon_green.png`} 
                    alt="CO2 Icon" 
                    style={{ width: '26px', height: '19px', color: '#0F4A46' }} 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SingleMetricCard
                title="Kapsam 1"
                value={`${co2Data.scope1} tCO2e`}
                icon={Flame}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SingleMetricCard
                title="Kapsam 2"
                value={`${co2Data.scope2} tCO2e`}
                icon={Zap}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SingleMetricCard
                title="Kapsam 3"
                value={`${co2Data.scope3} tCO2e`}
                icon={Recycle}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ChartCard title="Aylık Emisyon Dağılımı">
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={co2Data.monthlyEmissions}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="scope1" stackId="a" fill="#8884d8" />
                  <Bar dataKey="scope2" stackId="a" fill="#82ca9d" />
                  <Bar dataKey="scope3" stackId="a" fill="#ffc658" />
                </BarChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartCard title="Emisyon Dağılımı">
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie
                    data={co2Data.emissionDistribution}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={60}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {co2Data.emissionDistribution.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartCard title="Çalışan Başına CO2 Emisyonları">
              <ResponsiveContainer width="100%" height={200}>
                <div 
                  style={{ 
                    display: 'flex', 
                    height: '100%' 
                  }}
                >
                  <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#FFFFFF' }}>
                    {`${co2Data.perEmployeeEmissions} tCO2e/kişi`}
                  </span>
                </div>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  if (openProgress) return (
    <div 
        className="flex justify-center items-center h-screen" 
        style={{ backgroundColor: 'rgba(211, 211, 211, 0.4)' }}
    >
        <div 
            className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2" 
            style={{ borderColor: '#0F4A46' }}
        ></div>
    </div>
  );

  return (
    <div className={styles.rensDashboardLayout}>
      <Sidebar />
      <div className={`${styles.mainContent} ml-64`}>
        <Header userName={userName} />    
        {/* <div className={styles.toggleContainer}>
          <Switch
            checked={showPlatformOverview}
            onChange={toggleDashboard}
            name="dashboardToggle"
            className={styles.dashboardToggle}
          />
          <Typography variant="body2" className={styles.toggleLabel}>
            {showPlatformOverview ? "Kullanıcı Özeti" : "Platform Özeti"}
          </Typography>
        </div> */}
        <div className={styles.dashboardContainer}>
          {showPlatformOverview ? (
            <Typography variant="body2">Platform Overview (To be implemented)</Typography>
          ) : 
          
          (
           <UserInformationDashboard /> 
          )}
        </div>
      </div>
    </div>
  );
}