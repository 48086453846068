import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import OfferItemComponent from '../components/OfferItemComponent';
import { useAuth } from "../contexts/AuthProvider";
import EditItemModal from "../components/EditItemModal";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Snackbar } from '@mui/material';
import { FaEdit, FaTrashAlt } from 'react-icons/fa'; 
import styles from './MarketPlace.module.css';

const MyListingPage = () => {
    const [profileData, setProfileData] = useState(null);
    const [myListItems, setMyListItems] = useState([]);
    const [offers, setOffers] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const isMyItem = true;

    const { token } = useAuth();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const iconStyle = {
        width: '1.5rem',
        height: '1.5rem',
        marginRight: '0.75rem',
        color: '#FFFFFF',
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
                    headers: {
                        'x-access-token': token,
                    },
                });
                setProfileData(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }

        };
        const fetchMyItems = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listItem/myItems`, {
                    headers: {
                        'x-access-token': token,
                    },
                });
                setMyListItems(response.data.data);
            } catch (error) {
                console.error('Error fetching user items:', error);
            }
          };
        fetchProfile();
        fetchMyItems();
    }, [token]);

    

    const handleItemClick = (itemId, isMyItem) => {
        navigate(`/items/${itemId}`, { state: { isMyItem } });
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setShowEditModal(true);
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };

    const handleSave = async (updatedItem) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/listItem/${updatedItem.id}`, updatedItem, {
                headers: {
                    'x-access-token': token,
                },
            });
            setMyListItems((prevData) => (prevData.map((item) =>item.id === updatedItem.id ? updatedItem : item))
        );
            setShowEditModal(false);
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/listItem/${selectedItem.id}`, {
                headers: {
                    'x-access-token': token,
                },
            }).then(response => {
                if (response.status === 200) {
                    setMyListItems((prevData) => ({ listItems: prevData.listItems.filter((item) => item.id !== selectedItem.id)}));
                    setShowDeleteModal(false);
                    setSnackbarOpen(true);
                }
            });
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    if (!profileData) return (
        <div 
            className="flex justify-center items-center h-screen" 
            style={{ backgroundColor: 'rgba(211, 211, 211, 0.4)' }}
        >
            <div 
                className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2" 
                style={{ borderColor: '#0F4A46' }}
            ></div>
        </div>
    );

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div className="ml-64" style={{ flex: 1 }}>
                <Header userName={profileData.user.name} />
                <div 
                    className="w-full p-4" 
                    style={{
                        width: '100%',
                        maxWidth: '1700px',
                        margin: '0 auto',
                    }}
                >
                    <div className={styles.stepHeader}>
                        <img src="/rensmarketlogo.png" alt="Rens Market Logo" className={styles.stepLogo} />
                        <h2 className="text-xl font-semibold text-black">İlanlarım</h2>
                    </div>
                    <div className="bg-[#0F4A46] rounded-2xl p-6 mx-5">
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
                            {myListItems.map(item => (
                                <div
                                    key={item.id}
                                    className="bg-[#FFFFFF] rounded-xl p-3 relative group cursor-pointer"
                                    onClick={() => handleItemClick(item.id, true)}
                                >
                                    <img
                                        src={item.ItemPhotoHistories[0].source_url}
                                        alt={item.title}
                                        className="w-full h-24 object-cover rounded-lg mb-2"
                                    />
                                    <h3 style={{ color: '#484a50' }} className="font-medium text-sm mb-1">{item.title}</h3>
                                    <p style={{ color: '#484a50' }} className="text-xs mb-1">{item.description}</p>
                                    <p style={{ color: '#484a50' }} className="text-xs">
                                        <span className="font-medium">Miktar:</span> {item.amount} {item.unit}
                                    </p>
                                    <div className="absolute bottom-3 right-3 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleEdit(item);
                                            }}
                                            style={{ color: '#484a50' }}
                                            className="p-1.5 rounded-lg bg-white/70 hover:bg-white/20 transition-colors"
                                        >
                                            <FaEdit size={14} />
                                        </button>
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleDelete(item);
                                            }}
                                            style={{ color: '#484a50' }}
                                            className="p-1.5 rounded-lg bg-white/70 hover:bg-white/20 transition-colors"
                                        >
                                            <FaTrashAlt size={14} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {showEditModal && (
                        <EditItemModal
                            item={selectedItem}
                            onSave={handleSave}
                            onClose={() => setShowEditModal(false)}
                            setItem={setSelectedItem}
                        />
                    )}
                    {showDeleteModal && (
                        <DeleteConfirmationModal
                            onConfirm={handleDeleteConfirm}
                            onClose={() => setShowDeleteModal(false)}
                        />
                    )}
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={7000}
                        onClose={handleSnackbarClose}
                        message='Atık ilanı başarıyla silindi !'
                    />
                </div>
            </div>
        </div>

    );
};

export default MyListingPage;
