import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useAuth } from "../contexts/AuthProvider";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { FormControl } from '@mui/material';
import { FaSearch, FaEdit, FaTrash, FaGasPump, FaCar, FaBolt, FaRecycle } from 'react-icons/fa';


export default function DeleteDialog({ item, items, setItems, handleAction, url, itemName }) {
    const [open, setOpen] = React.useState(false);
    const { token } = useAuth();
    const [successMessage, setSuccessMessage] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSuccessMessage("");
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        try {
            console.log("handleDelete");
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}${url}/${item.id}`, {
                headers: {
                    'x-access-token': token,
                },
            }).then((response) => {
                let statusCode = response.status;

                console.log(statusCode);
                if (statusCode == "200") {
                    setSuccessMessage("Basariyla Guncellendi");
                    setItems(previousData => previousData.filter((data) => data.id != item.id))
                }
            });
        } catch (error) {
            console.error('Error handleDelete:', error);
        }   
    };

    return (

        <React.Fragment>
            <button variant="outlined" onClick={handleClickOpen} className="text-red-400 hover:text-red-300">
                <FaTrash />
            </button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event => {
                        handleDelete(event);
                    })
                }}
            >
                <DialogTitle>Emisyon Verisi Silme</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {item.emissionName} isimli emisyon verisini silmek istediginizden emin misiniz ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>IPTAL</Button>
                    <Button type="submit">KAYDET</Button>
                </DialogActions>
                {successMessage &&
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        severity="success">
                        <AlertTitle>Info</AlertTitle>
                        {successMessage}
                    </Alert>}
            </Dialog>

        </React.Fragment>

    );
}