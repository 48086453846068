import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from "../contexts/AuthProvider";
import EditItemModal from "../components/EditItemModal";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Snackbar } from '@mui/material';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const ProfilePage = () => {
    const [profileData, setProfileData] = useState(null);
    const [offers, setOffers] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [activeTab, setActiveTab] = useState('profile');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const { token } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
                    headers: { 'x-access-token': token },
                });
                setProfileData(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
                setSnackbarMessage('Profil bilgileri yüklenirken hata oluştu.');
                setSnackbarOpen(true);
            }
        };

        const getOffers = async () => {
            try {
                const offer = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/offer/myItems`, {
                    headers: { 'x-access-token': token },
                });
                setOffers(offer.data);
            } catch (error) {
                console.error('Error fetching offers:', error);
                setSnackbarMessage('Teklifler yüklenirken hata oluştu.');
                setSnackbarOpen(true);
            }
        };

        fetchProfile();
        getOffers();
    }, [token]);

    const handleItemClick = (itemId, isMyItem) => {
        navigate(`/items/${itemId}`, { state: { isMyItem } });
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setShowEditModal(true);
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };

    const handleSave = async (updatedItem) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/listItem/${updatedItem.id}`, updatedItem, {
                headers: { 'x-access-token': token },
            });
            setProfileData((prevData) => ({
                ...prevData,
                listItems: prevData.listItems.map((item) =>
                    item.id === updatedItem.id ? updatedItem : item
                ),
            }));
            setShowEditModal(false);
            setSnackbarMessage('İlan başarıyla güncellendi.');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error updating item:', error);
            setSnackbarMessage('İlan güncellenirken hata oluştu.');
            setSnackbarOpen(true);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/listItem/${selectedItem.id}`, {
                headers: { 'x-access-token': token },
            });
            setProfileData((prevData) => ({
                ...prevData,
                listItems: prevData.listItems.filter((item) => item.id !== selectedItem.id),
            }));
            setShowDeleteModal(false);
            setSnackbarMessage('İlan başarıyla silindi.');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error deleting item:', error);
            setSnackbarMessage('İlan silinirken hata oluştu.');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (!profileData) return (
        <div 
            className="flex justify-center items-center h-screen" 
            style={{ backgroundColor: 'rgba(211, 211, 211, 0.4)' }}
        >
            <div 
                className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2" 
                style={{ borderColor: '#0F4A46' }}
            ></div>
        </div>
    );
    
    const tabs = [
        { id: 'profile', label: 'Profil Bilgileri' },
        { id: 'listings', label: 'İlanlarım' },
        { id: 'offers', label: 'Teklifler' }
    ];

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div className="ml-64" style={{ flex: 1 }}>
                <Header />
                <div className="w-full p-4 bg-[#FFFFFF] min-h-screen"             
                    style={{
                        width: '100%',
                        maxWidth: '1700px',
                        margin: '0 auto',
                    }}
                >
                    <div className="flex gap-2 p-4 bg-[#0F4A46] rounded-2xl mb-4">
                        {tabs.map(tab => (
                            <button
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                className={`px-6 py-3 rounded-xl transition-colors duration-300 ${
                                    activeTab === tab.id
                                        ? 'bg-[#165853] text-white font-medium'
                                        : 'bg-[#0F4A46] text-white/70 hover:bg-[#165853]/50'
                                }`}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={activeTab}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.3 }}
                        >
                            {activeTab === 'profile' && (
                                <div className="bg-[#0F4A46] rounded-2xl p-6 text-white">
                                    <h2 className="text-2xl font-bold mb-6">Profil Bilgileri</h2>
                                    <div className="space-y-4">
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <p className="text-white/60 text-sm">Ad/Soyad</p>
                                                <p className="font-medium">{profileData.user.name} {profileData.user.surname}</p>
                                            </div>
                                            <div>
                                                <p className="text-white/60 text-sm">Email</p>
                                                <p className="font-medium">{profileData.user.email}</p>
                                            </div>
                                            <div>
                                                <p className="text-white/60 text-sm">Telefon</p>
                                                <p className="font-medium">{profileData.user.phone}</p>
                                            </div>
                                            <div>
                                                <p className="text-white/60 text-sm">Üyelik Tarihi</p>
                                                <p className="font-medium">
                                                    {new Date(profileData.user.createdAt).toLocaleDateString('tr-TR')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'listings' && (
                                <div className="bg-[#0F4A46] rounded-2xl p-6">
                                    <h2 className="text-2xl font-bold mb-6 text-white">İlanlarım</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
                                        {profileData.listItems.map(item => (
                                            <div
                                                key={item.id}
                                                className="bg-[#FFFFFF] rounded-xl p-3 relative group cursor-pointer"
                                                onClick={() => handleItemClick(item.id, true)}
                                            >
                                                <img
                                                    src={item.ItemPhotoHistories[0].source_url}
                                                    alt={item.title}
                                                    className="w-full h-24 object-cover rounded-lg mb-2"
                                                />
                                                <h3 style={{ color: '#484a50' }} className="font-medium text-sm mb-1">{item.title}</h3>
                                                <p style={{ color: '#484a50' }} className="text-xs mb-1">{item.description}</p>
                                                <p style={{ color: '#484a50' }} className="text-xs">
                                                    <span className="font-medium">Miktar:</span> {item.amount} {item.unit}
                                                </p>

                                                <div className="absolute bottom-3 right-3 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                                                    <button
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            handleEdit(item);
                                                        }}
                                                        style={{ color: '#484a50' }}
                                                        className="p-1.5 rounded-lg bg-white/10 hover:bg-white/20 transition-colors"
                                                    >
                                                        <FaEdit size={14} />
                                                    </button>
                                                    <button
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            handleDelete(item);
                                                        }}
                                                        style={{ color: '#484a50' }}
                                                        className="p-1.5 rounded-lg bg-white/10 hover:bg-white/20 transition-colors"
                                                    >
                                                        <FaTrashAlt size={14} />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {activeTab === 'offers' && (
                                <div className="bg-[#0F4A46] rounded-2xl p-6">
                                    <h2 className="text-2xl font-bold mb-6 text-white">Teklifler</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {offers.map(offer => (
                                            <div key={offer.id} className="bg-[#165853] rounded-xl p-4 text-white">
                                                <div className="mb-4">
                                                    <h3 className="font-medium mb-1">{offer.listItem.title}</h3>
                                                    <p className="text-sm text-white/70">{offer.listItem.user.email}</p>
                                                </div>
                                                <div className="text-sm">
                                                    <p className="text-white/60 mb-1">Teklif Mesajı</p>
                                                    <p>{offer.offerMessage}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </motion.div>
                    </AnimatePresence>
                </div>

                {showEditModal && (
                    <EditItemModal
                        item={selectedItem}
                        onSave={handleSave}
                        onClose={() => setShowEditModal(false)}
                        setItem={setSelectedItem}
                    />
                )}
                {showDeleteModal && (
                    <DeleteConfirmationModal
                        onConfirm={handleDeleteConfirm}
                        onClose={() => setShowDeleteModal(false)}
                    />
                )}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={7000}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                />
            </div>
        </div>
    );
};

export default ProfilePage;