import React from 'react';
import AuthProvider from "./contexts/AuthProvider";
import Routes from "./routes";
import Navbar from "./components/Navbar";

function App() {
  return (
      <AuthProvider>
        <Routes />
      </AuthProvider>
  );
}

export default App;
