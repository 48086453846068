import React, { useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight, Save, Flame, Zap, Recycle } from "lucide-react";
import axios from 'axios';

// Assume these services are properly imported
import ScopeService from '../services/ScopeService';
import AuthService from '../services/AuthService';

const EmissionDataForm = ({ onSubmit }) => {
 const [step, setStep] = useState(1);
 const [formData, setFormData] = useState({
   dataType: '',
   subType: '',
   dataSourceName: '',
   usageType: '',
   facility: '',
   activityData: '',
   activityData2: '',
   emissionFactor: '',
   customEmissionFactor: '',
   usagePeriodStart: '',
   usagePeriodEnd: '',
   fuelType: '',
   unit: '',
   category: '',
   gasType: '',
   productType: '',
   wasteType: '',
   managementActivity: '',
   productionType: '',
   waterUsageActivity: '',
   fuelForm: '',
   biofuelForm: '',
   biofuelType: '',
   vehicleType: '',
   materialCategory: '',
   materialType: '',
   wasteCategory: '',
   managementMethod: '',
   waterUsageType: '',
   country: '',
   emissionFactorType: '',
 });

 const [loading, setLoading] = useState(true);
 const [notification, setNotification] = useState({ message: '', type: '' });

 const [scope1FuelsData, setScope1FuelsData] = useState({});
 const [scope1BioenergyData, setScope1BioenergyData] = useState({});
 const [scope1CarsData, setScope1CarsData] = useState({});
 const [scope1OthersData, setScope1OthersData] = useState({});
 const [scope2Data, setScope2] = useState({});
 const [scope3MaterialuseData, setScope3MaterialuseData] = useState({});
 const [scope3WastedisposalData, setScope3WasteDisposalData] = useState({});
 const [scope3WaterData, setScope3WaterData] = useState({});
 const [scope3HotelstayData, setScope3HotelstayData] = useState({});

 useEffect(() => {
   const fetchData = async () => {
     setLoading(true);
     try {
       const scopeNames = [
         "scope1_fuels", "scope1_bioenergy", "scope1_cars", "scope1_others",
         "scope2", "scope3_materialuse", "scope3_wastedisposal", "scope3_water", "scope3_hotelstay"
       ];

       for (const scopeName of scopeNames) {
         const result = await ScopeService.getScopeWithTableName(scopeName);
         switch(scopeName) {
           case "scope1_fuels": setScope1FuelsData(result); break;
           case "scope1_bioenergy": setScope1BioenergyData(result); break;
           case "scope1_cars": setScope1CarsData(result); break;
           case "scope1_others": setScope1OthersData(result); break;
           case "scope2": setScope2(result); break;
           case "scope3_materialuse": setScope3MaterialuseData(result); break;
           case "scope3_wastedisposal": setScope3WasteDisposalData(result); break;
           case "scope3_water": setScope3WaterData(result); break;
           case "scope3_hotelstay": setScope3HotelstayData(result); break;
         }
       }
     } catch (error) {
       console.error("Error fetching scope data:", error);
       showNotification("Veri yüklenirken bir hata oluştu", "error");
     } finally {
       setLoading(false);
     }
   };

   fetchData();
 }, []);

 const resetState = () => {
   setFormData({
     dataType: '',
     subType: '',
     dataSourceName: '',
     usageType: '',
     facility: '',
     activityData: '',
     activityData2: '',
     emissionFactor: '',
     customEmissionFactor: '',
     usagePeriodStart: '',
     usagePeriodEnd: '',
     fuelType: '',
     unit: '',
     category: '',
     gasType: '',
     productType: '',
     wasteType: '',
     managementActivity: '',
     productionType: '',
     waterUsageActivity: '',
     fuelForm: '',
     biofuelForm: '',
     biofuelType: '',
     vehicleType: '',
     materialCategory: '',
     materialType: '',
     wasteCategory: '',
     managementMethod: '',
     waterUsageType: '',
     country: '',
     emissionFactorType: '',
   });
 };

 const handleInputChange = (name, value) => {
   setFormData({ ...formData, [name]: value });
 };

 const handleNextStep = () => {
   setStep(step + 1);
 };

 const handlePrevStep = () => {
   setStep(step - 1);
 };

 const isCustom = () => {
   return formData.emissionFactorType === 'custom';
 };

 const showNotification = (message, type = 'success') => {
   setNotification({ message, type });
   setTimeout(() => setNotification({ message: '', type: '' }), 5000);
 };

 const scopeTableNameMap = {
   "Sabit Yanma Kaynaklı Emisyonlar": "scope1_fuels",
   "Biyoyakıtların Sabit Yanmasından Kaynaklanan Emisyonlar": "scope1_bioenergy",
   "Şirket Araçlarından Kaynaklanan Emisyonlar": "scope1_cars",
   "Soğutucu & Diğer Gaz Sızıntıları": "scope1_others",
   "Satın Alınan Elektrik Kaynaklı Emisyonlar": "scope2",
   "Satın Alınan Mallara Ait Emisyonlar": "scope3_materialuse",
   "Atık Yönetiminden Kaynaklanan Emisyonlar": "scope3_wastedisposal",
   "Su Kullanımı Kaynaklı Emisyonlar": "scope3_water",
   "Personel Otel Konaklamasına Ait Emisyonlar": "scope3_hotelstay"
 };

 const handleSubmit = async () => {
   const scopeTableName = scopeTableNameMap[formData.subType];
   const scopeData = await ScopeService.getScopeWithTableName(scopeTableNameMap[formData.subType]);
   let matchedScopeId = 0;
   let unit = '';
   let tempScopeData = null
   if(scopeTableName == 'scope1_fuels') {
     tempScopeData = scopeData.records.find(item => item.activity == formData.fuelForm && item.fuel == formData.fuelType && item.unit == formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
   } else if (scopeTableName == 'scope1_bioenergy') {
     tempScopeData = scopeData.records.find(item => item.activity == formData.biofuelForm && item.fuel == formData.biofuelType && item.unit == formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit; 
   } else if (scopeTableName == 'scope1_cars') {
     tempScopeData= scopeData.records.find(item => item.activity == formData.dataType && item.car_type == formData.vehicleType && item.fuel == formData.fuelType && item.unit == formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
   } else if (scopeTableName == 'scope1_others') {
     tempScopeData = scopeData.records.find(item => item.activity === formData.category && item.emission_source === formData.gasType && item.unit == formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
   } else if (scopeTableName == 'scope2') {
     tempScopeData = scopeData.records.find(item => item.unit === formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
   } else if (scopeTableName == 'scope3_materialuse') {
     tempScopeData = scopeData.records.find(item => item.activity === formData.materialCategory && item.material === formData.materialType && item.production_type === formData.productionType && item.unit === formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
   } else if (scopeTableName == 'scope3_wastedisposal') {
     tempScopeData = scopeData.records.find(item => item.activity === formData.wasteCategory && item.waste_type === formData.wasteType && item.disposal_type === formData.managementMethod && item.unit === formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
   } else if (scopeTableName == 'scope3_water') {
     tempScopeData = scopeData.records.find(item => item.activity === formData.waterUsageActivity && item.type === formData.waterUsageType && item.unit === formData.unit)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
   } else if (scopeTableName == 'scope3_hotelstay') {
     tempScopeData =scopeData.records.find(item => item.country === formData.country)
     matchedScopeId = tempScopeData.id;
     unit = tempScopeData.unit;
     formData.activityData = formData.activityData * formData.activityData2;
   }
   
   const request = {
     emissionName: formData.dataSourceName,
     scopeTableName: scopeTableName,
     scopeId: matchedScopeId,
     amount: formData.activityData,
     unit: unit,
     isCustom: isCustom(),
     customCoefficient: formData.customEmissionFactor == "" ? null : formData.customEmissionFactor,
     startDate: formData.usagePeriodStart,
     endDate: formData.usagePeriodEnd
   };

   const token = AuthService.getCurrentUserToken();

   try {
    console.log(request)
     const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/emission`, request, {
       headers: {
         'Content-Type': 'application/json',
         'x-access-token': token,
       },
     });
     if (response.status == 201) {
       resetState();
       setStep(1);
       showNotification("Emisyon verisi başarıyla kaydedildi");
     }
   } catch (error) {
     console.error('Error creating emission:', error);
     showNotification("Emisyon verisi kaydedilirken bir hata oluştu", "error");
   }
 };

 function onlyUnique(value, index, array) {
   return array.indexOf(value) === index;
 }

 const renderStep1 = () => (
   <div className="space-y-4">
     <h2 className="text-2xl font-bold text-white">1. Emisyon Kapsamı</h2>
     <div className="flex justify-around">
       {[
         { name: 'Kapsam 1', icon: Flame },
         { name: 'Kapsam 2', icon: Zap },
         { name: 'Kapsam 3', icon: Recycle }
       ].map(({ name, icon: Icon }, index) => (
         <button
           key={index}
           onClick={() => handleInputChange('dataType', name)}
           className={`px-4 py-2 rounded-lg transition-colors duration-200 flex items-center ${
             formData.dataType === name 
               ? "bg-[#165853] text-white" 
               : "bg-[#0F4A46] text-white/70 hover:bg-[#165853]/50"
           }`}
         >
           <Icon className="mr-2 h-5 w-5" />
           {name}
         </button>
       ))}
     </div>
   </div>
 );

 const renderStep2 = () => {
   let subOptions = [];
   if (formData.dataType === 'Kapsam 1') {
     subOptions = ['Sabit Yanma Kaynaklı Emisyonlar', 'Biyoyakıtların Sabit Yanmasından Kaynaklanan Emisyonlar', 'Şirket Araçlarından Kaynaklanan Emisyonlar', 'Soğutucu & Diğer Gaz Sızıntıları'];
   } else if (formData.dataType === 'Kapsam 2') {
     subOptions = ['Satın Alınan Elektrik Kaynaklı Emisyonlar'];
   } else if (formData.dataType === 'Kapsam 3') {
     subOptions = ['Satın Alınan Mallara Ait Emisyonlar', 'Atık Yönetiminden Kaynaklanan Emisyonlar', 'Su Kullanımı Kaynaklı Emisyonlar', 'Personel Otel Konaklamasına Ait Emisyonlar'];
   }

   return (
     <div className="space-y-4">
       <h2 className="text-2xl font-bold text-white">2. Emisyon Kaynağı</h2>
       <select
         onChange={(e) => handleInputChange('subType', e.target.value)}
         value={formData.subType}
         className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
       >
         <option value="" className="text-black">Emisyon Kategorisi Seçin</option>
         {subOptions.map((option, index) => (
           <option className="text-black" key={index} value={option}>
             {option}
           </option>
         ))}
       </select>
     </div>
   );
 };

 const renderStep3 = () => {
   let extraFields = null;

   if (formData.subType === 'Sabit Yanma Kaynaklı Emisyonlar') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('fuelForm', e.target.value)}
           value={formData.fuelForm}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Yakıt Formu Seçin</option>
           {scope1FuelsData.records
             ?.map(item => item.activity)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('fuelType', e.target.value)}
           value={formData.fuelType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Yakıt Türü Seçin</option>
           {scope1FuelsData.records
             ?.filter(item => item.activity === formData.fuelForm)
             .map(item => item.fuel)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope1FuelsData.records
             ?.filter(item => item.activity === formData.fuelForm && item.fuel == formData.fuelType)
             .map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Biyoyakıtların Sabit Yanmasından Kaynaklanan Emisyonlar') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('biofuelForm', e.target.value)}
           value={formData.biofuelForm}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Biyoyakıt Formu Seçin</option>
           {scope1BioenergyData.records
             ?.map(item => item.activity)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('biofuelType', e.target.value)}
           value={formData.biofuelType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Biyoyakıt Türü Seçin</option>
           {scope1BioenergyData.records
             ?.filter(item => item.activity === formData.biofuelForm)
             .map(item => item.fuel)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope1BioenergyData.records
             ?.filter(item => item.activity === formData.biofuelForm && item.fuel == formData.biofuelType)
             .map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Şirket Araçlarından Kaynaklanan Emisyonlar') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('vehicleType', e.target.value)}
           value={formData.vehicleType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Araç Türü Seçin</option>
           {scope1CarsData.records
             ?.map(item => item.car_type)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('fuelType', e.target.value)}
           value={formData.fuelType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Yakıt Türü Seçin</option>
           {scope1CarsData.records
             ?.filter(item => item.car_type === formData.vehicleType)
             .map(item => item.fuel)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope1CarsData.records
             ?.filter(item => item.car_type === formData.vehicleType && item.fuel == formData.fuelType)
             .map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Soğutucu & Diğer Gaz Sızıntıları') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('category', e.target.value)}
           value={formData.category}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Kategori Seçin</option>
           {scope1OthersData.records
             ?.map(item => item.activity)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('gasType', e.target.value)}
           value={formData.gasType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Gaz Türü Seçin</option>
           {scope1OthersData.records
             ?.filter(item => item.activity === formData.category)
             .map(item => item.emission_source)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope1OthersData.records
             ?.filter(item => item.activity === formData.category && item.emission_source == formData.gasType)
             .map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Satın Alınan Elektrik Kaynaklı Emisyonlar') {
     extraFields = (
       <>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope2Data.records
             ?.map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Satın Alınan Mallara Ait Emisyonlar') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('materialCategory', e.target.value)}
           value={formData.materialCategory}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Malzeme Kategorisi Seçin</option>
           {scope3MaterialuseData.records
             ?.map(item => item.activity)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('materialType', e.target.value)}
           value={formData.materialType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Malzeme Türü Seçin</option>
           {scope3MaterialuseData.records
             ?.filter(item => item.activity === formData.materialCategory)
             .map(item => item.material)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('productionType', e.target.value)}
           value={formData.productionType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Üretim Türü Seçin</option>
           {scope3MaterialuseData.records
             ?.filter(item => item.activity === formData.materialCategory && item.material === formData.materialType)
             .map(item => item.production_type)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope3MaterialuseData.records
             ?.filter(item => item.activity === formData.materialCategory && item.material === formData.materialType && item.production_type === formData.productionType)
             .map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Atık Yönetiminden Kaynaklanan Emisyonlar') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('wasteCategory', e.target.value)}
           value={formData.wasteCategory}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Atık Kategorisi Seçin</option>
           {scope3WastedisposalData.records
             ?.map(item => item.activity)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('wasteType', e.target.value)}
           value={formData.wasteType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Atık Türü Seçin</option>
           {scope3WastedisposalData.records
             ?.filter(item => item.activity === formData.wasteCategory)
             .map(item => item.waste_type)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('managementMethod', e.target.value)}
           value={formData.managementMethod}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Yönetim Yöntemi Seçin</option>
           {scope3WastedisposalData.records
             ?.filter(item => item.activity === formData.wasteCategory && item.waste_type === formData.wasteType)
             .map(item => item.disposal_type)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope3WastedisposalData.records
             ?.filter(item => item.activity === formData.wasteCategory && item.waste_type === formData.wasteType && item.disposal_type === formData.managementMethod)
             .map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Su Kullanımı Kaynaklı Emisyonlar') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('waterUsageActivity', e.target.value)}
           value={formData.waterUsageActivity}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Su Kullanım Aktivitesi Seçin</option>
           {scope3WaterData.records
             ?.map(item => item.activity)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <select
           onChange={(e) => handleInputChange('waterUsageType', e.target.value)}
           value={formData.waterUsageType}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Su Kullanım Türü Seçin</option>
           {scope3WaterData.records
             ?.filter(item => item.activity === formData.waterUsageActivity)
             .map(item => item.type)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Faaliyet Verisi"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <select
           onChange={(e) => handleInputChange('unit', e.target.value)}
           value={formData.unit}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Birim Seçin</option>
           {scope3WaterData.records
             ?.filter(item => item.activity === formData.waterUsageActivity && item.type === formData.waterUsageType)
             .map(item => item.unit)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
       </>
     );
   } else if (formData.subType === 'Personel Otel Konaklamasına Ait Emisyonlar') {
     extraFields = (
       <>
         <select
           onChange={(e) => handleInputChange('country', e.target.value)}
           value={formData.country}
           className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
         >
           <option className="text-black" value="">Ülke Seçin</option>
           {scope3HotelstayData.records
             ?.map(item => item.country)
             .filter(onlyUnique)
             .sort()
             .map((item) => (
               <option className="text-black" key={item} value={item}>
                 {item}
               </option>
             ))}
         </select>
         <input
           type="number"
           placeholder="Gece Sayısı"
           value={formData.activityData}
           onChange={(e) => handleInputChange('activityData', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
         <input
           type="number"
           placeholder="Kişi Sayısı"
           value={formData.activityData2}
           onChange={(e) => handleInputChange('activityData2', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
       </>
     );
   }

   return (
     <div className="space-y-4">
       <h2 className="text-2xl font-bold text-white">3. Emisyon Detayları</h2>
       <input
         placeholder="Veri Kaynağı İsmi"
         value={formData.dataSourceName}
         onChange={(e) => handleInputChange('dataSourceName', e.target.value)}
         className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
       />
       {extraFields}
       <select
         onChange={(e) => handleInputChange('emissionFactorType', e.target.value)}
         value={formData.emissionFactorType}
         className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
       >
         <option value="" className="text-black">Emisyon Faktörü Seçin</option>
         <option value="default" className="text-black">Varsayılan emisyon faktörünü kullan</option>
         <option value="custom" className="text-black">Kendi emisyon faktörümü biliyorum</option>
       </select>
       {formData.emissionFactorType === 'custom' && (
         <input
           type="number"
           placeholder="Özel Emisyon Faktörü"
           value={formData.customEmissionFactor}
           onChange={(e) => handleInputChange('customEmissionFactor', e.target.value)}
           className="w-full bg-[#0F4A46] text-white placeholder-white/50 rounded-lg p-2"
         />
       )}
       <input
         type="date"
         placeholder="Kullanım Dönemi Başlangıcı"
         value={formData.usagePeriodStart}
         onChange={(e) => handleInputChange('usagePeriodStart', e.target.value)}
         className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
       />
       <input
         type="date"
         placeholder="Kullanım Dönemi Bitişi"
         value={formData.usagePeriodEnd}
         onChange={(e) => handleInputChange('usagePeriodEnd', e.target.value)}
         className="w-full bg-[#0F4A46] text-white rounded-lg p-2"
       />
     </div>
   );
 };

 const renderStep4 = () => (
   <div className="space-y-4">
     <h2 className="text-2xl font-bold text-white">4. Özet</h2>
     <div className="bg-[#165853] rounded-xl p-4">
       <p className="text-white"><strong>Emisyon Kapsamı:</strong> {formData.dataType}</p>
       <p className="text-white"><strong>Emisyon Kaynağı:</strong> {formData.subType}</p>
       <p className="text-white"><strong>Veri Kaynağı İsmi:</strong> {formData.dataSourceName}</p>
       <p className="text-white"><strong>Faaliyet Verisi:</strong> {formData.activityData} {formData.unit}</p>
       <p className="text-white"><strong>Kullanım Dönemi:</strong> {formData.usagePeriodStart} - {formData.usagePeriodEnd}</p>
       <p className="text-white"><strong>Emisyon Faktörü Tipi:</strong> {formData.emissionFactorType === 'custom' ? 'Özel' : 'Varsayılan'}</p>
       {formData.emissionFactorType === 'custom' && (
         <p className="text-white"><strong>Özel Emisyon Faktörü:</strong> {formData.customEmissionFactor}</p>
       )}
     </div>
   </div>
 );

 const renderCurrentStep = () => {
   switch (step) {
     case 1:
       return renderStep1();
     case 2:
       return renderStep2();
     case 3:
       return renderStep3();
     case 4:
       return renderStep4();
     default:
       return null;
   }
 };

 return (
   <div className="bg-[#0F4A46] rounded-2xl p-6">
     {notification.message && (
       <div className={`mb-4 p-4 rounded-lg ${notification.type === 'error' ? 'bg-red-500' : 'bg-green-500'}`}>
         {notification.message}
       </div>
     )}
     {renderCurrentStep()}
     <div className="mt-6 flex justify-between">
       {step > 1 && (
         <button 
           onClick={handlePrevStep}
           className="px-4 py-2 bg-[#165853] text-white rounded-lg hover:bg-[#1a6b64] transition-colors duration-200 flex items-center"
         >
           <ArrowLeft className="mr-2 h-4 w-4" /> Geri
         </button>
       )}
       {step < 4 ? (
         <button 
           onClick={handleNextStep}
           className="ml-auto px-4 py-2 bg-[#165853] text-white rounded-lg hover:bg-[#1a6b64] transition-colors duration-200 flex items-center"
         >
           İleri <ArrowRight className="ml-2 h-4 w-4" />
         </button>
       ) : (
         <button 
           onClick={handleSubmit}
           className="ml-auto px-4 py-2 bg-[#165853] text-white rounded-lg hover:bg-[#1a6b64] transition-colors duration-200 flex items-center"
         >
           Kaydet <Save className="ml-2 h-4 w-4" />
         </button>
       )}
     </div>
   </div>
 );
};

export default EmissionDataForm;